import { convertToModule } from 'factory/utils/clientUtils';
import styles from './search-no-results.module.scss';
import React from 'react';
import HK from 'factory/modules/HK'

const NoResults = () => {
    return (
        <div className={convertToModule(styles, "SNoResults__container")}>
            <img src={HK.getImage('/header/searchbox/no-results.svg')} alt="no-results.svg" className={convertToModule(styles, "SNoResults__img")} />
            {/* <h4 className={convertToModule(styles, "SNoResults__title")}>OOPS! NO RESULT FOUND</h4>
            <h6 className={convertToModule(styles, "SNoResults__small")}>Try again with different keywords</h6> */}
            {/* <h4 className={convertToModule(styles, "SNoResults__title")}>OOPS! NO RESULT FOUND</h4> */}
            <h6 className={convertToModule(styles, "SNoResults__small")}>Couldn't find anything based on your query. Please try searching something else or </h6>
            <a className={convertToModule(styles, "SNoResults__title")} href="/brand/muscleblaze?navKey=BR-539&itracker=w:home|products|;">Click here</a>
        </div>
    );
}
 
export default NoResults;