// import 'static/css/cart/cart.css';
import React, { Component } from 'react';
import { withRouter } from 'next/router';
//import TrustWidget from './desktop/TrustWidget';
import CartItem from './CartItem';
import CartComboItem from './CartComboItem';
//import ApplyOffer from './common/ApplyOffer';
//import OrderSummary from 'components/common/order-summary';
import HK from 'factory/modules/HK';
// store imports
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    cartOrderSummaryAction, cartVariantAction, cartPackAction,
    cartOfferAction, cartAppliedOfferAction, cartHeaderSummaryAction
} from 'components/Cart/action';
import { pageTypeAction } from 'components/pageTypeAction';
import { headerTextAction } from 'components/common/header/SingleLineHeader/action';
//import NoProdTemplate from './common/NoProdTemplate';
import Analytics from 'factory/modules/Analytics';
//import CartActionBottom from 'components/Cart/common/CartAction';
// import WidgetData from 'components/common/Widget';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';
import styles from '../../static/css/cart/cart.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
import { __localStorageGet } from 'factory/modules/HK_service';
let countIt = 0;
class CartLayerMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            noProdTemplate: false,
            wishListPopup: false,
            ampcartadded: false,
            currentPin: '',
            currentPinCode: '',
            currentCity: '',
            currentpath: null
        };
        this.closeWishlistPopup = this.closeWishlistPopup.bind(this);
        this.openWishListPopup = this.openWishListPopup.bind(this);
        this._changePin = this._changePin.bind(this);
        this.checkPin = this.checkPin.bind(this);
    }
    componentWillUnmount() {
        localStorage.removeItem('_datalayer');
    }
    componentDidMount() {
        this.initializeCart();
        if (window.location.pathname.indexOf('/sv/') > -1) {
            window.pageType = 'storeVariant';
        } else if (window.location.pathname.indexOf('/pk/') > -1) {
            window.pageType = 'pack';
        } else {
            let urlParm = HK.getSearchParmas();
            if (urlParm.navKey !== undefined && (urlParm.navKey.indexOf('GL-') > -1 || urlParm.navKey.indexOf('CP-') > -1 || urlParm.navKey.indexOf('CL-') > -1 || urlParm.navKey.indexOf('SCT-') > -1 || urlParm.navKey.indexOf('CBL-') > -1 || urlParm.navKey.indexOf('BR-') > -1 || urlParm.navKey.indexOf('SCT-') > -1)) {
                window.pageType = 'menuLanding';
            } else if (window.location.pathname == '/') {
                window.pageType = 'home';
            } else {
                window.pageType = this.props.pageType;
            }
        }
        if (window.pageType == 'cart') {
            localStorage.removeItem('_datalayer');
            window.dataLayer = [];
            HK._dataLayer({ pageType: window.pageType }, window.pageType);
            HK.updateScreenName();
        }
        let pin = HK.Cookies.get('hkPincode');
        if (!pin) {
            this._getLocationCall(false);
        } else {
            this.setState({
                currentPinCode: pin,
                currentPin: pin
            });
        }
    }
    initializeCart = () => {
        if (HK.getSearchParmas().token) {
            // console.log("TOKEN FOUND :: ", HK.getSearchParmas().token);
            this.checkForceLogin(HK.getSearchParmas().token);
        } else {
            //  console.log(":: TOKEN NOT FOUND ::");
            this.tempUseCreate();
        }
    };
    checkForceLogin = (token) => {
        this.props._pageTypeAction(this.props.pageType);
        HK.checkForceLogin(token).then(() => {
            this.ampcartCall();
        }).catch(err => {
            HK.errorPopup("Something went wrong, please try again.");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    };
    tempUseCreate = () => {
        const isUserLoggedIn = __localStorageGet('isUserLoggedIn');
        const authtoken = HK.Cookies.get('authtoken');
        if (!(isUserLoggedIn !== null && isUserLoggedIn !== 'false' && authtoken)) {
            let reqObj = {
                method: 'get',
                url: HK.config.APIS.userFlow.tempUserCreate
            };
            HK.ajaxCall(reqObj).then((res) => {
                if (!res.data.results.exception) {
                    HK.Cookies.set('authtoken', '"' + res.data.results.authToken + '"', 365);
                    localStorage.setItem('isUserLoggedIn', res.data.results.user.id);
                    localStorage.setItem('isTMPUser', 1);
                    HK.setCookieLoggedIn(res.data.results.user.id, 1);
                    this.ampcartCall();
                }
            }).catch(err => {
                HK.errorPopup("Something went wrong, please try again.", 'Got: exception::' + reqObj.url);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
            });
        }
        else {
            this.ampcartCall();
        }
    };
    ampcartCall() {
        if (HK.getSearchParmas().ampcart && HK.getSearchParmas().id && HK.getSearchParmas().vId) {
            let data = {
                storeVariantId: HK.getSearchParmas().id,
                vendorId: HK.getSearchParmas().vId,
                userId: HK.Cookies.get('assumeId') || localStorage.getItem('isUserLoggedIn'),
                quantity: 1
            };
            let reqObj = {
                method: 'post',
                url: HK.config.APIS.cart.addVariant,
                data: data
            };
            HK.ajaxCall(reqObj).then((res) => {
                if (!res.data.results.exception) {
                    this.cartCall();
                }
            }).catch(err => {
                HK.errorPopup("Something went wrong, please try again.", 'Got: exception::' + reqObj.url);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
            });
        }
        else {
            this.cartCall();
        }

    }
    notifycartEvent(variant) {
        let _userData = JSON.parse(localStorage.getItem('userData'));
        let nv = window.nv;
        if (variant.cartVar.length || variant.cartPacks.length) {
            if (variant.cartVar.length) {
                if (typeof nv !== 'undefined')
                    nv('event', 'Cart_Web', {
                        customer_name: _userData !== null ? _userData.firstName : '',
                        category: '',
                        sub_category: '',
                        product: variant.cartVar[0].sv_nm,
                        price_inr: variant.cartVar[0].totOffPr,
                        url: window.location.origin + variant.cartVar[0].url
                    }, 10, 2);
            }
            else if (variant.cartPacks.length) {
                if (typeof nv !== 'undefined')
                    nv('event', 'Cart_Web', {
                        customer_name: _userData !== null ? _userData.firstName : '',
                        category: '',
                        sub_category: '',
                        product: variant.cartPacks[0].pckName,
                        price_inr: variant.cartPacks[0].totOffPr,
                        url: window.location.origin + variant.cartPacks[0].url
                    }, 10, 2);
            }
        }
    }
    cartCall() {
        HK.ajaxCall({ method: "get", url: HK.config.APIS.cart.headerSummary }).then((res) => {
            if (!res.data.results.exception) {
                this.props._cartHeaderSummaryAction(res.data.results);
                // if (res.data.results.cartSummary.noItemsInCart) {
                //     if (this.props.location.pathname.split('/')[1] != 'checkout') {
                //         this.props._headerText("Your Cart (" + (res.data.results.cartSummary.noItemsInCart) + ")");
                //     }
                // }
                Analytics.scopeBranch().viewCart(res.data.results.cartSummary.cartVariantSummaryList);
            } else {
                HK.errorPopup("Something went wrong, please try again");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
        }).catch((err) => {
            console.log(err);
        });
        let reqObj = {
            method: "get",
            header: { crtId: HK.getSearchParmas().cartId || null },
            url: HK.config.APIS.cart.cartGet
        };
        if (!reqObj.header.crtId) {
            delete reqObj.header;
        }
        HK.ajaxCall(reqObj).then((res) => {
            if (!res.data.results.exception) {
                this.props._cartOrderSummaryAction(res.data.results.cartPricing);
                this.props._cartVariantAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartVar : []);
                this.props._cartPackAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartPacks : []);
                this.props._cartOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.cartOffers : []);
                this.props._cartAppliedOfferAction(res.data.results.cartPricing ? res.data.results.cartPricing.appOfrDtl : null);
                this.setState({
                    dataLoaded: true,
                    noProdTemplate: res.data.results.cartPricing ? false : true
                }, () => {
                    this.props._pageTypeAction(this.props.pageType);
                });
                if (res.data.results.cartPricing) {
                    Analytics.Scope().cartUpdate(res.data.results);
                    let cartData = res.data.results.cartPricing.cartVar;
                    this.notifycartEvent(res.data.results.cartPricing);
                    let dataCart = {}, shoppingCartItemIdsArr = [], shoppingCartItemBrandsArr = [], shoppingCartItemCatsArr = [];
                    dataCart.shoppingCartItemIds = '';
                    dataCart.shoppingCartItemBrands = '';
                    dataCart.shoppingCartItemCats = '';
                    for (var i = 0; i < cartData.length; i++) {

                        shoppingCartItemIdsArr.push(cartData[i].navKey);
                        shoppingCartItemBrandsArr.push(cartData[i].brand);
                        shoppingCartItemCatsArr.push(cartData[i].catName);
                    }
                    dataCart.shoppingCartTotalPayable = res.data.results.cartPricing && res.data.results.cartPricing.totPay.toString();
                    dataCart.appliedOffer = '';
                    if (res.data.results.cartPricing.appOfrDtl) {
                        dataCart.appliedOffer = res.data.results.cartPricing.appOfrDtl.nm;
                    }
                    if (shoppingCartItemIdsArr.length) { dataCart.shoppingCartItemIds = shoppingCartItemIdsArr.join(','); }
                    if (shoppingCartItemBrandsArr.length) { dataCart.shoppingCartItemBrands = shoppingCartItemBrandsArr.join(','); }
                    if (shoppingCartItemCatsArr.length) { dataCart.shoppingCartItemCats = shoppingCartItemCatsArr.join(','); }
                    let _ecomObj2 = {
                        "eCommerce": {
                            "currencyCode": "INR",
                        }
                    };
                    this.setState({
                        currentCity: res.data.results.cityNm,
                        currentPinCode: this.state.currentPin
                    });
                    HK._updateDataLayer({ ...dataCart, ..._ecomObj2 });
                }
                //window.dataLayer.push(dataCart)
            } else {
                HK.errorPopup("Something went wrong, please try again");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
        }).catch((err) => {
            console.log(err);
            HK.errorPopup("Something went wrong, please try again");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props._CARTReducer.noItemsInCart != prevProps._CARTReducer.noItemsInCart) {
            this.cartCall();
        }
    }


    _getLocationCall(isCartCall) {
        const userId = HK.loggedIn();

        if (userId) {
            let reqObj = {
                method: "get",
                url: HK.config.APIS.pinCode.getPincode + '?userId=' + userId
            };
            HK.ajaxCall(reqObj).then((res) => {
                if (!res.data.results.exception) {
                    if (res.data.results.pin) {
                        HK.Cookies.set('hkPincode', res.data.results.pin, 365);
                        this.setState({
                            currentPinCode: res.data.results.pin,
                            currentPin: res.data.results.pin
                        });
                        if (isCartCall) { this.cartCall(); }
                    }
                } else {
                    this.setState({
                        errorMsg: res.data.results.msgs[0],
                        changePin: false
                    });
                }
            }).catch(err => {
                console.log(err);
                HK.errorPopup("Something went wrong, please try again");
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
            });
        }
    }
    checkPin(eventAction, eventLabel) {
        if (!isNaN(this.state.currentPin) && this.state.currentPin.length !== 6) {
            HK.toast('Enter a Valid Pincode.');
            return;
        }
        HK.Cookies.set('hkPincode', this.state.currentPin, 365);
        // let data = {
        //     pin: this.state.currentPin,
        //     pageType: window.pageType,
        //     userId: HK.loggedIn()
        // }
        //Analytics.Scope().triggerClickStreamEvent('PIN-CHANGE', data);
        this.cartCall();
        let eventdata = {
            'event': 'Is_MB_Cart_Pincode-Change',
            'eventCategory': 'Cart Page',
            'eventAction': eventAction,
            'eventLabel': eventLabel,
        };
        HK._setDataLayer(eventdata);
    }
    codeInput(e) {
        this.setState({
            currentPin: e.target.value
        });
    }
    _emptyPincode = () => {
        this.setState({ currentPin: '' });
    };
    _changePin(eventAction, eventLabel) {
        this.setState({ currentPinCode: '' });
        let eventdata = {
            'event': 'Is_MB_Cart_Pincode-Change',
            'eventCategory': 'Cart Page',
            'eventAction': eventAction,
            'eventLabel': eventLabel,
        };
        HK._setDataLayer(eventdata);
    }
    premiumCart = () => {
        this.cartCall();
    };
    openWishListPopup = () => {
        this.setState({
            wishListPopup: true
        });
    };
    closeWishlistPopup = () => {
        this.setState({
            wishListPopup: false
        });
    };
    redirectToCart = () => {
        window.location.href = '/cart/Cart.action';
    };
    render() {
        // let productname = this.props._cartOrderSummaryReducer && this.props._cartOrderSummaryReducer.cartVar &&
        //     this.props._cartOrderSummaryReducer.cartVar.map((d) => {
        //         return d.sv_nm
        //     })
        return (
            this.state.dataLoaded ?
                <div className={convertToModule(styles, "cart-layout")}>
                    {
                        ((this.props._cartPackReducer || this.props._cartVariantReducer) && (this.props._cartPackReducer.length > 0 || this.props._cartVariantReducer.length > 0)) &&
                        <React.Fragment>
                            <div className={convertToModule(styles, "card mrgn-t-10 box-shdw-none")}>
                                {!HK.isMobile() && <div className={convertToModule(styles, "cart-location float-wrapper")}>
                                    {/* {
                                        !this.state.currentPinCode && <div className={convertToModule(styles, "cart_check_pin float-wrapper")}>
                                            <div className={convertToModule(styles, "input_block")}>
                                                <img className={convertToModule(styles, "loaction_icon")} src={HK.getImage("/icons/location.svg")} />
                                                <input placeholder="Enter Pin Code" name="pincode" value={this.state.currentPin} onChange={this.codeInput.bind(this)} />
                                                {
                                                    this.state.currentPin &&
                                                    <a className={convertToModule(styles, "close_button")} onClick={this._emptyPincode} >
                                                        <img src={HK.getImage("/icons/close-icon.svg")} alt="close-icon.svg"/>
                                                    </a>
                                                }
                                            </div>
                                            <button onClick={this.checkPin.bind(this,'pinCode -Check',productname)}>Check</button>
                                        </div>
                                    }
                                    {
                                        this.state.currentPinCode &&
                                        <div className={convertToModule(styles, "cart_check_pin float-wrapper")}>
                                            <div className={convertToModule(styles, "input_block")}>
                                                <img className={convertToModule(styles, "loaction_icon")} src={HK.getImage("/icons/location.svg")} />
                                                <p className={convertToModule(styles, "text")}> Delivers in <b>{this.state.currentPinCode} {this.state.currentCity ? ',' + this.state.currentCity : ''}</b> </p>
                                            </div>
                                            <a className={convertToModule(styles, "change_button")} onClick={this._changePin.bind(this,'pinCode -Change',productname)}>Change</a>
                                        </div>
                                    } */}
                                </div>
                                }
                                <div className={convertToModule(styles, 'cart-prdct-tbl')}>
                                    <div className={convertToModule(styles, (this.props.isCartPopup ? '' : 'HK-pagecontainer'))}>
                                        <div className={convertToModule(styles, "tbs-cart-page-section")}>
                                            {
                                                this.props._cartVariantReducer &&
                                                this.props._cartVariantReducer.map((e, i) => {
                                                    if (this.props._cartVariantReducer.length - 1 === i) {
                                                        return (
                                                            <CartItem menuActual={this.props.menuActual} isCheckoutScreen={this.props.isCheckoutScreen} {...this.props} data={e} dataIndex={i} key={countIt++} desktopCartMenu={true} />
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                            {
                                                this.props._cartPackReducer &&
                                                this.props._cartPackReducer.map((e, i) => {
                                                    if (i === 0) {
                                                        if (e) {
                                                            return (
                                                                <CartComboItem menuActual={this.props.menuActual} data={e} dataIndex={i} key={countIt++} desktopCartMenu={true} comboItem={true} />
                                                            );
                                                        } else {
                                                            return "";
                                                        }
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={convertToModule(styles, "gz-cart-menu-actions")} onClick={this.props.clickHandler}>
                                <div onClick={this.redirectToCart.bind(this)} className={convertToModule(styles, 'gcma-cart-btn')}>
                                    View Cart({this.props._CARTReducer && this.props._CARTReducer.noItemsInCart})
                                </div>
                                <div className={convertToModule(styles, "gcma-close-btn")} onClick={this.props.clickHandler}>
                                    Continue shopping
                                </div>
                            </div>
                            {/* <div className={convertToModule(styles, "cart-sum-tbl")}>
                                <OrderSummary isMobile={HK.isMobile()} pagePattern={this.props.pagePattern} onClick={this.props.clickHandler} desktopCartMenu={true} {...this.props} />
                            </div> */}
                            {/* {HK.isMobile() && !this.props.isCartPopup && <CartActionBottom isMobile={HK.isMobile()} {...this.props} />} */}
                        </React.Fragment>
                    }
                </div>
                :
                (
                    HK.isMobile() ? <SkeletonPattern pattern={[8]} /> : <div className={convertToModule(styles, (this.props.isCartPopup ? '' : 'HK-pagecontainer'))}><SkeletonPattern pattern={[8]} /></div>
                )
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _cartOrderSummaryAction: cartOrderSummaryAction,
        _cartVariantAction: cartVariantAction,
        _cartPackAction: cartPackAction,
        _cartOfferAction: cartOfferAction,
        _cartAppliedOfferAction: cartAppliedOfferAction,
        _cartHeaderSummaryAction: cartHeaderSummaryAction,
        _pageTypeAction: pageTypeAction,
        _headerText: headerTextAction
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        _cartOrderSummaryReducer: state.cartOrderSummaryReducer,
        _cartVariantReducer: state.cartVariantReducer,
        _cartPackReducer: state.cartPackReducer,
        _cartAppliedOfferReducer: state.cartAppliedOfferReducer,
        _isLoggedin: state.isLogedInReducer,
        _CARTReducer: state.CARTReducer
    };
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CartLayerMenu)
);