import React from 'react';
import HK from 'factory/modules/HK';
import IntersectionObserver from '../../../IntersectionObserver';
import { connect } from 'react-redux';
import Link from 'components/Assets/LinkHref';
import styles from '../../HeaderTop/header-top.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
import { cartIcon } from '../../headerConstentIcon';
const Cart = ({ CART }) => {

    const triggerInViewEvent = () => {
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    widgetName: 'Cart Icon',
                    eventName: "CART_ICON",
                }
                HK.triggerComponentViewEvent(eventData);
            } catch (err) {
                console.log(err)
            }
        }
    }
    const triggerClickEvent = () => {
        const clickStream = {
            widgetName: 'Cart Icon',
            eventName: "CART_ICON",
            eventType: 'menu_cart_click',
            landingUrl: '/cart/Cart.action'
        }
        HK.triggerComponentClickEvent(clickStream)
    }
    return (
        <Link nextJSReload={true} onClick={() => { triggerClickEvent() }} to='/cart/Cart.action'>
            <IntersectionObserver onChange={triggerInViewEvent()}>
                {cartIcon(24)}
            </IntersectionObserver>
            {CART.noItemsInCart > 0 && <span className={convertToModule(styles, "cart_count")}>{CART.noItemsInCart || 0}</span>}

        </Link>
    );
}
function mapStateToProps(state) {
    return {
        CART: state.CARTReducer,
    };
}
export default connect(mapStateToProps)(Cart);
//export default Cart;