import React from 'react';
import HK from 'factory/modules/HK'
import Itrack from 'utility/Itrack';
import IntersectionObserver from 'components/common/IntersectionObserver';
import styles from '../search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const BrandsAndCategories = ({data, handleClick, searchInput, trackInViewSearch, trackSearchClick}) => {
    if(!data || data.length === 0)  return null;

    const returnUrl = (ele) => {
        let link = ele.url;
        if (link && link.indexOf('SCT') > -1) {
            link += '&cache=1'
        }
        if (ele.brName && link) {
            let brandName = ele.brName.toLowerCase().split(" ").join("-")
            link = [link.slice(0, 1), brandName + '-' + link.slice(1)].join("");
        }
        link && searchInput ? link += "&search="+searchInput.replace(/ /g, '+') : link += "&search"
        return link;
    }

    const searchTxtHighlighter = (str) => {
        let text = str.toLowerCase();
        let search = searchInput ? searchInput.toLowerCase() : "";
        let newStr = text.replace(search, "<span class='fnt-bld'>" + search + "</span>");
        return newStr.split(' in ').join(" in <span>") + "</span>";
    }
    return (
        <div className={convertToModule(styles, "search-box__BC")}>
            <h6 className={convertToModule(styles, "search-box__title")}>BRAND & CATEGORIES</h6>

            <div className={convertToModule(styles, "search-box__BC__items")}>
                {
                    data.map((item, index) => {
                        const url = returnUrl(item)
                        return <IntersectionObserver onChange={trackInViewSearch(HK.config.searchActions.brandsandcategories || "", item, ++index)}>
                        <Itrack  isLink isFullUrl ind={index + 1} childOf={'Brand & Categories'} nam={item.nm} url={url}>
                            <h6 dangerouslySetInnerHTML={{ __html: searchTxtHighlighter(item.nm) }} onClick={(event) => {handleClick(event, item); trackSearchClick(HK.config.searchActions.brandsandcategories || "", item, ++index)}} />
                        </Itrack>
                        </IntersectionObserver>
                    })
                }
            </div>
        </div>
    );
}
 
export default BrandsAndCategories;