import config from 'factory/modules/config';
import HK from 'factory/modules/HK';
import Head from 'next/head';
let defaultMetaDesc = "Get Personalized Health Drink As Per Your Child's Nutritional Needs From Gritzo. A whey based all natural milk drink with 3X Protein without any preservatives or artificial sugar."
// todo amp handling
const SSRHead = (props) => {
    let noFollowNoIndex = false;
    let noFollow = false;
    let noIndex = false;
    let gbot_noIndex = false;
    let fullPathName = '';
    let fullUrl = '';
    if (typeof window !== "undefined") {
        fullPathName = window.location.pathname;
        fullUrl = window.location.href;
    }
    let __query = props.query;
    let __url = `${config.AppURL}${props.requestUrl || ''}`;
    let __page;
    let pageType = props.pageType;
    const results = props.data && props.data.results;
    if (results && results.packs && results.packs.page) {
        __page = results.packs.page;
    } else {
        __page = props.data && props.data.results && props.data.results.page;
    }
    let __productId = props.productId;
    let __preloadImages = props.preloadImages;
    let seoData = __page && __page.pgSeo || null;
    let canonical = __page && __page.pgCnclUrl && { ...__page.pgCnclUrl };
    let isPublished = false;
    let _currentURL = __url.replace('view=desktop', '').replace('view=mobile');
    let ampURL = '';
    let isSCTPage = false;
    if (_currentURL.indexOf('?itracker') > -1) {
        _currentURL = _currentURL.split('?itracker')[0];
    } if (_currentURL.indexOf('&itracker') > -1) {
        _currentURL = _currentURL.split('&itracker')[0];
    }
    _currentURL = _currentURL.substring(_currentURL.length - 1) === '?' ? _currentURL.replace('/?', '') : _currentURL;
    if (canonical) {
        if (pageType === "storeVariant") {
            canonical.urlFragment = '/sv' + canonical.urlFragment;
            isPublished = props.data.results.is_published;
        }
        if (pageType === "pack") {
            canonical.urlFragment = '/pk' + canonical.urlFragment;
        }
        if (canonical.navKey && canonical.navKey.indexOf('SCT-') > -1) {
            isSCTPage = true;
        }
        _currentURL = config.AppURL + (canonical.landingPageCustomUrlFragment ? canonical.landingPageCustomUrlFragment : canonical.urlFragment) + (canonical.navKey !== null ? '?navKey=' + canonical.navKey : '');
    }
    if (_currentURL.indexOf('navKey') > -1) {
        let newURL = _currentURL.split('?navKey')[0];
        let navkey = __query && __query.navKey;
        if (navkey) {
            if (navkey.indexOf('navkey') > -1 || navkey.indexOf('?') > -1 || navkey.indexOf('/') > -1) {
                navkey = this.filterNavKey(navkey);
                _currentURL = newURL + (navkey ? '?navKey=' + navkey : '');
            }
        }
    }
    if (seoData && seoData.fullUrl) {
        _currentURL = seoData.fullUrl;
    }
    if (seoData && seoData.canonicalUnknown) {
        _currentURL = '';
    }
    // if (__url.indexOf('filterSelected') === -1 && __url.indexOf('~') > -1) {
    //     _currentURL = '';
    // }
    // if (__url.indexOf('filterSelected') > -1) {
    //     let search = __url.split('?')[1] || [];
    //     let searchArr = search.split('&');
    //     let map = {};
    //     searchArr.forEach(item => {
    //         let [key, value] = item.split('=')
    //         map[key] = value;
    //     });
    //     _currentURL = __url.split('?')[0] + (map.navKey ? `?navKey=${map.navKey}` : '') + (map.filterSelected ? `&filterSelected=${map.filterSelected}&fl=${map.filterSelected}` : '') + (map.brands && map.brands.indexOf('~') === -1 ? `&brands=${map.brands}` : '');
    // }
    // SSR
    let _title = seoData && seoData.title || "Gritzo: Personalized Nutrition &amp; SuperMilk Protein Drinks for Kids";
    let _metaDesc = seoData && seoData.metaDesc || defaultMetaDesc;
    let _metaKeywords = '';
    if (typeof window !== "undefined") {
        if (window.location.pathname === '/') {
            _metaKeywords = seoData && seoData.metaKeywrd || "";
        } else {
            _metaKeywords = ''
        }
    }
    let _removeCanonical = false;
    let _removeAmp = false;
    let pageUrl = __url.replace('view=desktop', '').replace('view=mobile');
    if (pageUrl.indexOf('~') > -1 && pageUrl.indexOf('filterSelected') === -1) {
        if (__query && __query.brands && isSCTPage) {
            let brand = __query.brands.split('~');
            if (brand && brand.length > 1) {
                _removeCanonical = true;
                _removeAmp = true;
            }
        }
    }
    if ((__url.indexOf('/sv/') > -1 && isPublished) || __url.indexOf('SCT') > -1 || __url.indexOf('BR') > -1 || __url.indexOf('CL') > -1 || __url.indexOf('CBL') > -1 || pageType === 'home') {
        const isBestSeller = __url.indexOf('/best-seller') > -1;
        if (isBestSeller) {
            ampURL = '';
        }
    } else {
        ampURL = '';
    }
    if (pageType === 'coupon') {
        ampURL = '';
    }
    // //remove amphtml
    // if ((__url.indexOf('fn-att-gender') > -1 && __url.indexOf('brands') > -1) || __url.indexOf('/gender/for-all') > -1) {
    //     ampURL = '';
    // }
    let _deepLinkProduct = '';
    let _deepLinkCategory = '';
    if (pageType === "storeVariant") {
        let __navkey = __query && __query.navKey;
        if (__navkey) {
            _deepLinkProduct = __navkey;
        } else if (__productId) {
            _deepLinkProduct = __navkey;
        }
    }
    if ([1, 2].includes(props.catLevel)) {
        let __navkey = __query && __query.navKey;
        if (__navkey) {
            _deepLinkCategory = __navkey;
        }
    }

    //og images start
    let ogImage = HK.getImage("/logos/mb-logo.svg");
    if (__page && __page.pgBanners && __page.pgBanners[0] !== undefined && Object.keys(__page.pgBanners[0].pgBnrSlots).length) {
        for (let i = 0; i < Object.keys(__page.pgBanners[0].pgBnrSlots).length; i++) {
            let currentKey = [Object.keys(__page.pgBanners[0].pgBnrSlots)[i]];
            if (__page.pgBanners[0].pgBnrSlots[currentKey].images.length && Object.keys(__page.pgBanners[0].pgBnrSlots[currentKey].images[0]).length) {
                ogImage = __page.pgBanners[0].pgBnrSlots[currentKey].images[0][HK.isMobile() ? "m_link" : "o_link"]
                break;
            }
        }
    }
    if (props.data && props.data.results) {
        if (pageType === "storeVariant" && props.data.results.pr_img && props.data.results.pr_img[props.isMobile ? "m_link" : "o_link"]) {
            ogImage = props.data.results.pr_img[props.isMobile ? "m_link" : "o_link"];
        }
        if (pageType === "pack" && props.data.results.packs && props.data.results.packs.image && props.data.results.packs.image[props.isMobile ? "m_link" : "o_link"]) {
            ogImage = props.data.results.packs.image[props.isMobile ? "m_link" : "o_link"];
            _title = props.data.results.packs.nm + " | gritzo"
        }
    }

    if (!ogImage) {
        ogImage = HK.getImage("/logos/mb-logo.svg");
    }
    if ((__url.indexOf('~') > -1 && __url.indexOf('filterSelected') === -1) || props.isSearch) {
        noFollow = true
    }
    if (__url.indexOf('~') > -1 && __url.indexOf('filterSelected') === -1) {
        gbot_noIndex = true
    }
    if (fullPathName && fullPathName.indexOf('/articles/') > -1) {
        noFollowNoIndex = true
    }
    return (seoData || pageType === "pack") ? <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>{_title}</title>
        {!_removeCanonical && <link rel="canonical" href={_currentURL} />}
        {_metaKeywords && <meta name="keywords" content={_metaKeywords} />}
        {noFollow && <meta name="robots" content="nofollow" />}
        {(gbot_noIndex || noIndex) && <meta name={gbot_noIndex ? 'googlebot' : 'robots'} content="noindex" />}
        {_deepLinkProduct && <meta name="branch:deeplink:productId" content={_deepLinkProduct} />}
        {_deepLinkProduct && <meta name="branch:deeplink:productView" content={"true"} />}
        {_deepLinkCategory && <meta name="branch:deeplink:categoryId" content={_deepLinkCategory} />}
        {noFollowNoIndex ? <meta name="robots" content="noindex,nofollow" /> : ''}
        <meta name="description" content={_metaDesc} />
        <meta property="og:title" content={_title} />
        <meta property="og:url" content={_currentURL} />
        <meta property="og:description" content={_metaDesc} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="gritzo" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="www.gritzo.com" />
        <meta name="twitter:title" content={_title} />
        <meta name="twitter:description" content={_metaDesc} />
        <meta name="twitter:creator" content="gritzo" />
        <meta name="twitter:image:src" content={ogImage} />
        <meta name="twitter:domain" content="www.gritzo.com" />
        {
            __preloadImages && __preloadImages.map((imageUrl, k) => {
                return imageUrl ? <link rel="preload" as="image" href={imageUrl} key={k} /> : ""
            })
        }
        {/* <script type="text/javascript"
            dangerouslySetInnerHTML={{
                __html: `
              !function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
              e.load("2GOUg2ZHJUderoxHx9msuqTVruG","https://healthkart-dataplane.rudderstack.com")}();
          `
            }}
        ></script>  */}
    </Head>
        :
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <title>Gritzo: Personalized Nutrition &amp; SuperMilk Protein Drinks for Kids</title>
            <link rel="canonical" href="https://www.gritzo.com" />
            {noFollowNoIndex ? <meta name="robots" content="noindex,nofollow" /> : ''}
            <meta property="og:title" content="gritzo" />
            <meta property="og:url" content="https://www.gritzo.com" />
            <meta name="description" content="gritzo is India's #1 Online Sports Nutrition & Bodybuilding Supplement Brand. Shop 100% Authentic Supplements Today! ✅ 89+ Lac Boxes Sold!" />
            <meta property="og:description" content="" />
            <meta property="og:image" content={ogImage} />
            <meta property="og:site_name" content="gritzo" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="www.gritzo.com" />
            <meta name="twitter:title" content="gritzo" />
            <meta name="twitter:description" content="" />
            <meta name="twitter:creator" content="gritzo" />
            <meta name="twitter:image:src" content={ogImage} />
            <meta name="twitter:domain" content="www.gritzo.com" />
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.1.0/css/all.css" media="print" onload='this.media="all"' />
        </Head>
}

export default SSRHead