export const userIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_92337" data-name="Group 92337" transform="translate(-36 -631)">
                <rect id="Boundary" width="24" height="24" transform="translate(36 631)" fill="none" />
                <path id="user" d="M20.8,22.178a.906.906,0,0,1-.959-.959V19.3a2.825,2.825,0,0,0-2.877-2.877H9.294A2.825,2.825,0,0,0,6.418,19.3v1.918a.906.906,0,0,1-.959.959.906.906,0,0,1-.959-.959V19.3a4.748,4.748,0,0,1,4.794-4.794h7.671A4.748,4.748,0,0,1,21.76,19.3v1.918A.906.906,0,0,1,20.8,22.178ZM13.13,12.589A4.748,4.748,0,0,1,8.336,7.794a4.794,4.794,0,0,1,9.589,0A4.748,4.748,0,0,1,13.13,12.589Zm0-7.671a2.825,2.825,0,0,0-2.877,2.877,2.877,2.877,0,1,0,5.753,0A2.825,2.825,0,0,0,13.13,4.918Z" transform="translate(34.371 631.412)" stroke="#fff" stroke-width="0.2" />
            </g>
        </svg>
    )
}

export const HomeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_92338" data-name="Group 92338" transform="translate(-36 -702)">
                <rect id="Boundary" width="24" height="24" transform="translate(36 702)" fill="none" />
                <g id="door" transform="translate(44.123 684.154)">
                    <g id="Group_3" data-name="Group 3" transform="translate(-6.088 20.75)">
                        <path id="Path_9" data-name="Path 9" d="M49,137.1a.593.593,0,0,0-.593.593v6.1H45.135v-6.1a.593.593,0,0,0-.593-.593H40.081a.593.593,0,0,0-.593.593v6.1H36.213v-6.1a.593.593,0,1,0-1.187,0v6.692a.593.593,0,0,0,.593.593H49a.593.593,0,0,0,.593-.593V137.7A.593.593,0,0,0,49,137.1Zm-8.329,6.692v-5.5h3.274v5.5H40.674Z" transform="translate(-32.254 -127.639)" stroke="#1a1a1a" stroke-width="0.2" />
                        <path id="Path_10" data-name="Path 10" d="M19.94,27.152l-9.464-9.463a.594.594,0,0,0-.839,0L.174,27.152a.593.593,0,0,0,.839.839l9.043-9.043L19.1,27.992a.593.593,0,1,0,.839-.839Z" transform="translate(0 -17.516)" stroke="#1a1a1a" stroke-width="0.2" />
                    </g>
                </g>
            </g>
        </svg>

    )
}

export const OrdersIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_92775" data-name="Group 92775" transform="translate(-36 -478)">
                <rect id="Boundary" width="24" height="24" transform="translate(36 478)" fill="none" />
                <g id="noun-order-2046095" transform="translate(-34.047 441.113)">
                    <path id="Path_109268" data-name="Path 109268" d="M112.377,145.943l-.983-.432-2.36-1.039q-1.419-.628-2.841-1.253-1.234-.544-2.467-1.086c-.4-.174-.793-.365-1.195-.528l-.016-.007c.116.2.23.4.346.6v-9.523c0-.442.012-.883,0-1.323v-.019l-1.049.6.983.432,2.36,1.039q1.419.628,2.841,1.253,1.234.544,2.467,1.086c.4.174.793.365,1.195.528l.016.007c-.116-.2-.23-.4-.346-.6V145.2c0,.442-.009.883,0,1.323v.019a.7.7,0,1,0,1.395,0v-9.523c0-.442.009-.883,0-1.323v-.019a.683.683,0,0,0-.346-.6l-.983-.432-2.36-1.039q-1.419-.628-2.841-1.253-1.235-.544-2.467-1.086c-.4-.177-.8-.351-1.195-.528l-.016-.007a.729.729,0,0,0-.7,0,.7.7,0,0,0-.346.6v9.523c0,.442-.009.883,0,1.323v.019a.683.683,0,0,0,.346.6l.983.432,2.36,1.039q1.419.628,2.841,1.253,1.234.544,2.467,1.086c.4.177.8.351,1.195.528l.016.007a.756.756,0,0,0,.537.07.7.7,0,0,0,.486-.858.656.656,0,0,0-.319-.416Z" transform="translate(-30.078 -87.855)" />
                    <path id="Path_109269" data-name="Path 109269" d="M343.661,141.538l-.983.432-2.36,1.039q-1.419.628-2.841,1.253-1.234.544-2.467,1.086c-.4.174-.8.342-1.195.528l-.016.007,1.049.6v-9.523c0-.442.012-.883,0-1.323v-.019c-.116.2-.23.4-.346.6l.983-.432,2.36-1.039q1.419-.628,2.841-1.253,1.234-.544,2.467-1.086c.4-.174.8-.342,1.2-.528l.016-.007-1.049-.6V140.8c0,.442-.009.883,0,1.323v.019a.7.7,0,1,0,1.395,0v-9.523c0-.442.009-.883,0-1.323v-.019a.7.7,0,0,0-.346-.6.729.729,0,0,0-.7,0l-.983.432-2.36,1.039q-1.419.628-2.841,1.253-1.234.544-2.467,1.086c-.4.174-.8.337-1.195.528l-.016.007a.683.683,0,0,0-.346.6v9.523c0,.442-.009.883,0,1.323v.019a.7.7,0,0,0,.346.6.729.729,0,0,0,.7,0l.983-.432,2.36-1.039q1.419-.628,2.841-1.253,1.235-.544,2.467-1.086c.4-.174.8-.337,1.195-.528l.016-.007a.7.7,0,0,0,.321-.416.7.7,0,0,0-.486-.858.812.812,0,0,0-.537.07Z" transform="translate(-252.201 -87.799)" />
                    <path id="Path_109270" data-name="Path 109270" d="M121.929,42.878l-.983.432-2.36,1.039q-1.419.628-2.841,1.253-1.234.544-2.467,1.086c-.4.174-.8.342-1.195.528l-.016.007h.7l-.983-.432-2.36-1.039q-1.419-.628-2.841-1.253-1.234-.544-2.467-1.086c-.4-.174-.793-.363-1.195-.528l-.016-.007q-.084.638-.167,1.274l.983-.388q1.175-.464,2.353-.93l2.846-1.123q1.235-.488,2.469-.974c.4-.156.8-.3,1.195-.472l.016-.007h-.372l.983.388q1.175.464,2.353.93l2.846,1.123q1.234.488,2.469.974c.4.156.793.33,1.195.472l.016.007a.7.7,0,0,0,.537-.07.7.7,0,0,0,.251-.953.8.8,0,0,0-.416-.321l-.983-.388-2.362-.932q-1.426-.565-2.855-1.128l-2.462-.972c-.393-.156-.784-.328-1.183-.467a.683.683,0,0,0-.535.053c-.2.081-.409.163-.614.242l-2.227.879L106.4,41.218q-1.283.506-2.564,1.014c-.467.184-.935.37-1.4.553-.021.009-.042.016-.065.026a.713.713,0,0,0-.5.579.687.687,0,0,0,.332.7c.33.144.66.291.988.435l2.371,1.046q1.426.628,2.853,1.258l2.457,1.083c.393.172.786.349,1.179.518a.816.816,0,0,0,.458.088,1.051,1.051,0,0,0,.274-.088l.128-.056.611-.27q1.112-.492,2.227-.981l2.839-1.251,2.574-1.135c.47-.207.937-.414,1.407-.618l.065-.028a.7.7,0,0,0,.321-.416.7.7,0,0,0-.486-.858.772.772,0,0,0-.544.065Z" transform="translate(-30.47)" />
                    <path id="Path_109271" data-name="Path 109271" d="M455.11,179.349v3.029a.7.7,0,0,0,1.395,0v-3.029a.7.7,0,1,0-1.395,0Z" transform="translate(-368.693 -133.823)" />
                    <path id="Path_109272" data-name="Path 109272" d="M223.713,84.53l.981.4,2.355.969,2.85,1.172q1.231.506,2.464,1.014c.4.163.793.344,1.2.493l.016.007a.69.69,0,0,0,.537-.07.7.7,0,0,0,.251-.953.829.829,0,0,0-.416-.321l-.981-.4-2.355-.969-2.85-1.172q-1.231-.506-2.464-1.014c-.4-.163-.793-.344-1.2-.493l-.016-.007a.69.69,0,0,0-.537.07.7.7,0,0,0-.251.953A.829.829,0,0,0,223.713,84.53Z" transform="translate(-146.647 -42.389)" />
                </g>
            </g>
        </svg>
    )
}

export const WishlistIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.209" height="22" viewBox="0 0 22.209 22">
            <g id="Group_92334" data-name="Group 92334" transform="translate(-37 -409)">
                <rect id="Boundary" width="22" height="22" transform="translate(37 409)" fill="none" />
                <g id="Path_108977" data-name="Path 108977" transform="translate(37.926 410.393)" fill="#fff">
                    <path d="M 10.64183616638184 18.41707801818848 C 5.929135322570801 15.13007354736328 2.780388116836548 11.79874706268311 1.519326210021973 8.759707450866699 C 0.6338561773300171 6.625047206878662 0.6952061653137207 4.703917026519775 1.696796178817749 3.204117059707642 C 2.645906209945679 1.782886981964111 4.368686199188232 0.8999969959259033 6.192836284637451 0.8999969959259033 C 7.708055973052979 0.8999969959259033 9.040546417236328 1.509606957435608 9.944855690002441 2.616517066955566 L 10.64183616638184 3.469636917114258 L 11.33881664276123 2.616517066955566 C 12.24311637878418 1.509606957435608 13.57561588287354 0.8999969959259033 15.09083652496338 0.8999969959259033 C 16.91518592834473 0.8999969959259033 18.63804626464844 1.782827019691467 19.58709526062012 3.203947067260742 C 20.58861541748047 4.703667163848877 20.6498966217041 6.624846935272217 19.76428604125977 8.759806632995605 C 18.50328254699707 11.79873180389404 15.3545446395874 15.13006401062012 10.64183616638184 18.41707801818848 Z" stroke="none" />
                    <path d="M 10.64183616638184 17.31545066833496 C 16.01413726806641 13.47263717651367 18.11884498596191 10.37696838378906 18.93297576904297 8.414966583251953 C 19.69795608520508 6.570796966552734 19.66533660888672 4.941687107086182 18.83863639831543 3.703767061233521 C 18.05443572998047 2.529476881027222 16.61836624145508 1.799996972084045 15.09083652496338 1.799996972084045 C 14.37273597717285 1.799996972084045 13.02094650268555 1.980046987533569 12.03578662872314 3.185916900634766 L 10.64183616638184 4.892167091369629 L 9.247885704040527 3.185926914215088 C 8.262725830078125 1.980046987533569 6.91093635559082 1.799996972084045 6.192836284637451 1.799996972084045 C 4.665506362915039 1.799996972084045 3.229516267776489 2.529546976089478 2.44524621963501 3.70393705368042 C 1.618486166000366 4.941946983337402 1.585796117782593 6.570996761322021 2.350606203079224 8.414767265319824 C 3.164822578430176 10.37696647644043 5.269541263580322 13.4726448059082 10.64183616638184 17.31545066833496 M 10.64183616638184 19.5100269317627 C 4.919785976409912 15.61450672149658 1.918806195259094 12.07063674926758 0.6880561709403992 9.104646682739258 C -2.609044075012207 1.156146287918091 6.80847692489624 -2.645072221755981 10.64183616638184 2.047106981277466 C 14.47518920898438 -2.645073175430298 23.89272499084473 1.156147241592407 20.59560585021973 9.104646682739258 C 19.36486625671387 12.07063674926758 16.36387634277344 15.61450672149658 10.64183616638184 19.5100269317627 Z" stroke="none" fill="#20262e" />
                </g>
            </g>
        </svg>

    )
}

export const savedPaymentIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_92836" data-name="Group 92836" transform="translate(-36 -702)">
                <rect id="Boundary" width="24" height="24" transform="translate(36 702)" fill="none" />
                <path id="Union_271" data-name="Union 271" d="M1.22,19.651A1.22,1.22,0,0,1,0,18.43V7.855a1.22,1.22,0,0,1,1.22-1.22H3.254V1.22A1.22,1.22,0,0,1,4.474,0h16.27a1.22,1.22,0,0,1,1.22,1.22V9.482a1.22,1.22,0,0,1-1.22,1.22h-.814a.407.407,0,0,1,0-.814h.814a.406.406,0,0,0,.407-.407v-4.6H4.068V6.635H17.491a1.22,1.22,0,0,1,1.22,1.22V18.43a1.22,1.22,0,0,1-1.22,1.22ZM.814,7.855V18.43a.407.407,0,0,0,.407.407h16.27a.407.407,0,0,0,.407-.407V7.855a.407.407,0,0,0-.407-.407H1.22A.407.407,0,0,0,.814,7.855ZM4.068,4.068H21.151V2.441H4.068Zm0-2.847v.407H21.151V1.22a.407.407,0,0,0-.407-.407H4.475A.407.407,0,0,0,4.068,1.22Zm8.455,16.41a1.949,1.949,0,1,1,0-3.9,1.927,1.927,0,0,1,1.1.344,1.949,1.949,0,1,1-.288,2.969l0,0c-.025-.025-.043-.055-.066-.081a1.926,1.926,0,0,1-.163-.2,1.778,1.778,0,0,1-.088-.163h0c-.03-.055-.063-.108-.088-.166a1.921,1.921,0,0,1-.07-.225h0c-.013-.046-.031-.089-.041-.136a1.83,1.83,0,0,1,0-.732c.005-.029.011-.057.017-.086a1.93,1.93,0,0,1,.1-.305h0c0-.007,0-.015.007-.022a1.931,1.931,0,0,1,.122-.24,1.124,1.124,0,0,0-.532-.133,1.136,1.136,0,1,0,.163,2.26.407.407,0,1,1,.116.805,1.942,1.942,0,0,1-.278.02ZM13.644,16q.015.057.035.113a1.03,1.03,0,0,0,.066.124h0c.012.022.022.046.036.067a1.092,1.092,0,0,0,.12.148,1.136,1.136,0,1,0,0-1.546,1.092,1.092,0,0,0-.12.148h0c-.014.021-.024.045-.036.067a1.041,1.041,0,0,0-.066.124q-.02.056-.035.113h0c-.009.032-.022.063-.028.1h0a1.106,1.106,0,0,0,0,.448h0C13.622,15.941,13.634,15.973,13.644,16Zm-11.2,1.612a.407.407,0,1,1,0-.814h6.1a.407.407,0,0,1,0,.814Zm0-2.034a.407.407,0,1,1,0-.814H4.474a.407.407,0,0,1,0,.814Zm0-2.848a.407.407,0,0,1-.407-.407h0V9.075a.407.407,0,0,1,.407-.407H6.1a.407.407,0,0,1,.407.407v3.254a.407.407,0,0,1-.407.407Zm.407-.813H5.695V9.482H2.847Z" transform="translate(37.035 704.59)" stroke="#1a1a1a" stroke-width="0.2" />
            </g>
        </svg>

    )
}

export const policiesIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_92342" data-name="Group 92342" transform="translate(-36 -1007)">
                <rect id="Boundary" width="24" height="24" transform="translate(36 1007)" fill="none" />
                <path id="Union_248" data-name="Union 248" d="M1.312,22.115A1.293,1.293,0,0,1,0,20.837V1.278A1.293,1.293,0,0,1,1.312,0H18.562a1.293,1.293,0,0,1,1.312,1.278V20.837a1.293,1.293,0,0,1-1.312,1.278Zm-.188-1.477H18.749V1.477H7.482V6.256L5.492,4.317,3.538,6.22V1.477H1.125Zm8.343-2.92a.447.447,0,0,1-.422-.411V16.9a.416.416,0,0,1,.422-.411h6.375a.447.447,0,0,1,.422.411v.411a.416.416,0,0,1-.422.411Zm-5.437-4.2a.448.448,0,0,1-.422-.411v-.411a.417.417,0,0,1,.422-.411H15.843a.447.447,0,0,1,.422.411V13.1a.417.417,0,0,1-.422.411Zm0-3.766a.448.448,0,0,1-.422-.411V8.927a.417.417,0,0,1,.422-.411H15.843a.447.447,0,0,1,.422.411v.411a.416.416,0,0,1-.422.411Z" transform="translate(38.016 1007.77)" stroke="#fff" stroke-width="0.2" />
            </g>
        </svg>

    )
}

export const forwordIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="7.13" height="12.071" viewBox="0 0 7.13 12.071">
            <path id="Path_113061" data-name="Path 113061" d="M11.878,107.232l-.4.4a.662.662,0,0,1-.934,0l-4.511-4.511-4.516,4.516a.662.662,0,0,1-.934,0l-.4-.4a.661.661,0,0,1,0-.934l5.377-5.4a.675.675,0,0,1,.468-.212h0a.675.675,0,0,1,.467.212l5.372,5.381a.667.667,0,0,1,.193.472A.66.66,0,0,1,11.878,107.232Z" transform="translate(107.828) rotate(90)" fill="#535664" />
        </svg>

    )
}

export const authinticateProduct = (height) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={height} height={height} viewBox="0 0 32 32">
            <g id="Group_98667" data-name="Group 98667" transform="translate(-249 -46)">
                <path id="Union_199" data-name="Union 199" d="M2.947,9.821a.982.982,0,1,1,0-1.964H12.77a.982.982,0,0,1,0,1.964ZM.982,5.893a1,1,0,0,1-.7-.286.982.982,0,0,1,.7-1.678H14.73a.98.98,0,0,1,.852.491.97.97,0,0,1,0,.981.98.98,0,0,1-.852.491ZM2.947,1.964a1,1,0,0,1-.854-.491A.982.982,0,0,1,2.947,0H12.77a.982.982,0,0,1,.848,1.473.986.986,0,0,1-.848.491Z" transform="translate(258.129 57.509)" fill="#20262e" />
                <g id="Group_96359" data-name="Group 96359" transform="translate(249 46)">
                    <path id="Union_1" data-name="Union 1" d="M18.667,29.341a1.333,1.333,0,1,1,0-2.667H24a2.669,2.669,0,0,0,2.667-2.668V18.673a1.333,1.333,0,0,1,2.667,0v5.334A5.335,5.335,0,0,1,24,29.341Zm-13.334,0A5.335,5.335,0,0,1,0,24.007V18.672a1.333,1.333,0,0,1,2.667,0v5.335a2.667,2.667,0,0,0,2.667,2.668h5.334a1.333,1.333,0,1,1,0,2.667Zm22-17.517a1.334,1.334,0,0,1-.666-1.155V5.334A2.667,2.667,0,0,0,24,2.668H18.667a1.334,1.334,0,1,1,0-2.668H24a5.335,5.335,0,0,1,5.334,5.334V10.67a1.334,1.334,0,0,1-2,1.155ZM1.333,12A1.333,1.333,0,0,1,0,10.67V5.334A5.335,5.335,0,0,1,5.334,0h5.334a1.333,1.333,0,0,1,0,2.667H5.334A2.667,2.667,0,0,0,2.667,5.334V10.67A1.333,1.333,0,0,1,1.334,12Z" transform="translate(1.832 1.578)" fill="#20262e" />
                    <rect id="Boundary" width={height} height={height} fill="none" />
                </g>
            </g>
        </svg>
    )
}

export const backContactIcon = () => {
    return (
        <svg id="Component_193_122" data-name="Component 193 – 122" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <rect id="Rectangle_298" data-name="Rectangle 298" width="28" height="28" rx="10" fill="#20262e" opacity="0.1" />
            <path id="Path_108788" data-name="Path 108788" d="M3.019,6.351l3.095,3.08a.882.882,0,0,1-1.245,1.25L.26,6.094h0a.883.883,0,0,1,0-1.249h0L4.869.257a.882.882,0,0,1,1.245,1.25L3.019,4.587l-.878.874Z" transform="translate(9.963 8.64)" fill="#20262e" />
        </svg>

    )
}

export const searchIcon = (size) => {
    return (
        <svg id="_Trailing_Icon" data-name="📍Trailing Icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
            <rect id="Boundary" width={size} height={size} fill="none" />
            <path id="Union_2" data-name="Union 2" d="M15404.78,14378.678l-4.075-4.076c.006,0,.895-.9.9-.9l4.076,4.074a.635.635,0,0,1-.483,1.051A.642.642,0,0,1,15404.78,14378.678Zm-8.9-2.984a6.336,6.336,0,1,1,5.4-2.308l-.9.9a6.37,6.37,0,0,1-4.019,1.429C15396.207,14375.713,15396.045,14375.706,15395.884,14375.693Zm-2.081-10.747a5.091,5.091,0,1,0,2.551-.685A5.095,5.095,0,0,0,15393.8,14364.946Zm-1.586,4.961a.637.637,0,0,1-.318-.552,4.459,4.459,0,0,1,4.457-4.457.637.637,0,1,1,0,1.273,3.184,3.184,0,0,0-3.183,3.184.636.636,0,0,1-.319.552.626.626,0,0,1-.318.086A.634.634,0,0,1,15392.217,14369.907Z" transform="translate(-15389 -14362)" fill="#414c58" />
        </svg>

    )
}

export const cartIcon = (size) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
            <g id="Group_91531" data-name="Group 91531" transform="translate(-337 -46)">
                <g id="Group_92813" data-name="Group 92813">
                    <rect id="Boundary" width="24" height="24" transform="translate(337 46)" fill="none" />
                    <path id="Path_8293" data-name="Path 8293" d="M125.945,26.18H133.6l-1.372-13.9h-2.4v2.815a.62.62,0,0,1-1.24,0V12.279h-5.3v2.815a.62.62,0,1,1-1.24,0V12.279h-2.4l-1.372,13.9Zm-3.888-15.14v-.887a3.888,3.888,0,0,1,7.776,0v.887h2.955v0a.618.618,0,0,1,.615.558l1.489,15.09a.62.62,0,0,1-.61.73H117.609v0c-.02,0-.04,0-.061,0a.619.619,0,0,1-.554-.675l1.49-15.092a.62.62,0,0,1,.62-.607h2.955Zm6.536,0v-.887a2.648,2.648,0,0,0-5.3,0v.887h5.3Z" transform="translate(222.736 41.035)" fill="#20262e" stroke="#20262e" stroke-width="0.4" />
                </g>
                {/* <g id="Ellipse_1603" data-name="Ellipse 1603" transform="translate(353 47)" fill="#d1342a" stroke="#fff" stroke-width="1">
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="4" fill="none" />
                </g> */}
            </g>
        </svg>


    )
}