import React from 'react';
import HK from 'factory/modules/HK';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {searchActiveAction} from './action';
import IntersectionObserver from '../../../IntersectionObserver';
import { searchIcon } from '../../headerConstentIcon';
const Search = (props) => {
    const triggerClick = (data) =>{
        const eventData = {
            widgetName: HK.config.eventNames.SEARCH_ICON.toLowerCase() || "",
            eventName: HK.config.eventNames.SEARCH_ICON || "",
            itemName: "search icon"
        }
        HK.triggerComponentClickEvent(eventData);
    }

    const triggerView = () => { 
        return (inView) => {
            if (!inView) return;
            try {
                const eventData={
                    widgetName: 'Header Search Icon',
                    eventName: "HEADER_SEARCH_ICON_VIEW",
                }
              HK.triggerComponentViewEvent(eventData);
            } catch (err) {
                console.log(err)
            }
        }
    }

    return (
        <IntersectionObserver onClick={()=>{props._searchActiveAction(true);triggerClick()}} onChange={triggerView()} >
            
            {searchIcon(24)}
            {/* <svg className={convertToModule(styles, "search-icon")}  xmlns="http://www.w3.org/2000/svg" width="18.066" height="18.005" viewBox="0 0 18.066 18.005"> <path id="Icon_awesome-search" data-name="Icon awesome-search" d="M17.264,15.134l-3.408-3.408a.82.82,0,0,0-.581-.239h-.557a7.107,7.107,0,1,0-1.231,1.231v.557a.82.82,0,0,0,.239.581l3.408,3.408a.817.817,0,0,0,1.159,0l.967-.967a.824.824,0,0,0,0-1.162ZM7.111,11.486a4.376,4.376,0,1,1,4.376-4.376A4.373,4.373,0,0,1,7.111,11.486Z" transform="translate(0.25 0.25)" fill="#fff" stroke="#001d42" strokeWidth="0.5"/> </svg> */}
        </IntersectionObserver>
    );
}

// Store value
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _searchActiveAction: searchActiveAction,
    }, dispatch);
}
export default connect(null, mapDispatchToProps)(Search);
