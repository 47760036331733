import React from 'react';
import Itrack from 'utility/Itrack';
import styles from '../search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const TopSearchItem = ({ ele, index, ...props }) => {
    return (
        <Itrack classname={convertToModule(styles, "search-box__TS__item")} isFullUrl isLink={true} url={ele.url} ind={index + 1} childOf={'top-searches'} nam={ele.name.toLowerCase()} ac_sel={'ac_sel=' + encodeURIComponent(ele.name)} >
            <div className={convertToModule(styles, "search-box__TS__item__img-container")} onClick={()=>props.onTopSearchClick(ele)}>
                <img src={ele.imageUrl} alt={ele.name} />
            </div>
            <p className={convertToModule(styles, "search-box__TS__item__text")} onClick={()=>props.onTopSearchClick(ele)}>
                {ele.name}
            </p>
        </Itrack>
    );
}

export default TopSearchItem;