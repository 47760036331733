import React, { useEffect, useState } from "react";
import styles from './LoyaltyInfoPopup.module.scss'
import { __getImage, __localStorageGet } from "factory/modules/HK_service";
import config from "factory/modules/config";
import HK from "factory/modules/HK";
import AddToCart from "utility/templates/Generic/AddToCart";
import IntersectionObserver from "components/common/IntersectionObserver";
import UniversalAnalytics from "factory/modules/UniversalAnalytics";

const LoyaltyInfoPopup = (props) => {
    const [membershipProduct, setMembershipProduct] = useState(null);
    const [loyalityDetail, setLoyalityDetail] = useState(null);
    const [exclusiveSecItem, setExclusiveSecItem] = useState('');
    const [memberShipDuration, setMemberShipDuration] = useState('');
    let userId = __localStorageGet('isUserLoggedIn') || (__localStorageGet('userData')  &&  __localStorageGet('userData').uH) || '';


    useEffect(() => {
        if (loyalityDetail) {
            setMembershipProduct(loyalityDetail);
        }
    }, [loyalityDetail])

    useEffect(() => {
        LoyalityProgramData();
    }, [])

    useEffect(() => {
        if (userId) {
            getLoyaltyPageSection(userId)
        }
    }, [userId])

    const createMembershipProduct = (membershipObject) => {
        if (membershipObject) {
            let tempCartObj = {
                id: membershipObject.id,
                vendorId: membershipObject.vendorId,
                name: membershipObject.nm,
                category: membershipObject.catName,
                offerPrice: membershipObject.offer_pr,
                img: membershipObject && membershipObject.pr_img && membershipObject.pr_img && membershipObject.pr_img.o_link || '',
                isConsultProduct: membershipObject.isConsultProduct,
                leafNode: membershipObject && membershipObject.catName || '',
                secondaryCategory: membershipObject && membershipObject.secondary_category_name || '',
                seccategory: membershipObject && membershipObject.secondary_category || '',
                brName: membershipObject && membershipObject.brTag && membershipObject.brTag.brName
            };
            return tempCartObj
        } else {
            return null
        }
    }

    const LoyalityProgramData = () => {
        let userId = JSON.parse(localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')).uH : "";
        let api = config.APIS.loyalty.loyaltyLevelDetails.replace("USER_ID", userId);
        let req = {
            method: "get",
            url: api
        };
        HK.ajaxCall(req).then((res) => {
            const _res = res && res.data && res.data.results;
            if (_res && !_res.exception) {
                if(_res.hkLoyaltyUserDetails && _res.hkLoyaltyUserDetails.membershipPurchaseValidityStr){
                    setMemberShipDuration(_res.hkLoyaltyUserDetails.membershipPurchaseValidityStr)
                }else if(_res.hkLoyaltyUserDetails && _res.hkLoyaltyUserDetails.membershipPurchaseValidity){
                    setMemberShipDuration(_res.hkLoyaltyUserDetails.membershipPurchaseValidityStr + 'Year')
                }
                if (_res.loyaltyLevelDetails && _res.loyaltyLevelDetails.length > 0) {
                    _res.loyaltyLevelDetails.map(eachLoyaltyObj => {
                        if (eachLoyaltyObj.role === config.LOYALITY_ROLES.PREMIUM_USER) {
                            setLoyalityDetail(createMembershipProduct(eachLoyaltyObj.membershipStoreVariant));
                        }
                    })
                }
            }
        });
    };

    const getLoyaltyPageSection = (userId) => {
        let req = {
            method: "get",
            url: HK.config.APIS.loyalty.loyaltyPageData.replace("USER_ID", userId)
        };
        HK.ajaxCall(req).then((res) => {
            const _res = res && res.data && res.data.results;
            if (_res && !_res.exception) {
                if (_res && _res.page && _res.page.pgSections && _res.page.pgSections.length > 0) {
                    _res.page.pgSections.map(eachSec => {
                        if (eachSec.pgDesignTyp === config.PAGE_SECTION_TYPE.LOYALTY_BENEFIT && eachSec.type === config.PAGE_SECTION_DESIGN_TYPE.LOYALTY_BENEFIT) {
                            setExclusiveSecItem(eachSec.scItms)
                        }
                    })
                }
            }

        }).catch((err) => {
            console.log(err);
            HK.errorPopup("Something went wrong, please try again");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    };

    const triggerInViewEvent = () => {
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    eventName: "loyalty_info_view",
                }
                HK.triggerComponentViewEvent(eventData);
            } catch (err) {
                console.log(err)
            }
        }

    };

    const triggerProductInViewEvent = () => {
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    event_name: 'select_promotion',
                    'reward_points_available': '',
                    'value': '',
                    'promotion_name': '',
                    'creative_name': '',
                    'creative_slot': '',
                    items: [
                        {
                            'item_name': 'Loyalty_onboarding',
                            'item_brand': membershipProduct && membershipProduct.brName || '',
                            'variant_name': membershipProduct && membershipProduct.name || '',
                            'item_category': membershipProduct && membershipProduct.category || '',
                        }
                    ]
                }

                UniversalAnalytics.LoyaltyDataLayer(eventData);
            } catch (err) {
                console.log(err)
            }
        }
    }

    return (
        <IntersectionObserver onChange={triggerInViewEvent()}>
            {
                membershipProduct ?
                    <IntersectionObserver onChange={triggerProductInViewEvent()}>

                        <div className={styles['loylt-popup-cntnr']}>
                            <div className={styles['popup-body-cntnr']}>
                                <img src={__getImage('/loyalty/close_icon.svg')} alt="close_icon" className={styles['close']} onClick={() => props.setAddPremiumPopup(false)} />
                                <div className={styles['popup-header-cntnr']}>
                                    <img src={__getImage('/loyalty/heading-frame.svg')} alt="loyalty" className={styles['loylty-frame']} />
                                    <div className={styles["second-heading"]}>
                                        BENEFITS
                                    </div>
                                </div>

                                <div className={styles['benefit-container']}>
                                    {
                                        exclusiveSecItem && exclusiveSecItem.length > 0 ?
                                            exclusiveSecItem.map((eachItem, index) => {
                                                return (
                                                    <div key={'each-offr_' + index} className={styles['first-benefit-cntnr']}>
                                                        <img src={__getImage('/loyalty/loyalty_without_star.svg')} alt="loylty" className={styles['loyalty-icon']} />
                                                        <div className={styles['dtl-cntnr']}>
                                                            <span className={styles['normal']}>
                                                                {eachItem.dis_nm}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : <></>
                                    }
                                </div>

                                <div className={styles['premium-info-cntnr']}>
                                    <div className={styles['premium-ttl-cntnr']}>
                                        Get Gritzo Loyalty
                                    </div>
                                    {
                                       memberShipDuration ?
                                            <div className={styles['prem-amnt-cntnr']}>
                                                <b>₹{membershipProduct.offerPrice}</b> for <b>{memberShipDuration}</b>
                                            </div>
                                            : <></>
                                    }
                                </div>
                                {
                                    membershipProduct ?
                                        <AddToCart
                                            id={membershipProduct.id}
                                            vendorID={membershipProduct.vendorId}
                                            eventName={"add_remove_loyalty_product"}
                                            eventCategory='Loyalty Page'
                                            widgetName="Loyalty Purchase"
                                            widgetIndex={1}
                                            handleAddToCartClickCallback={() => props.setAddPremiumPopup(false)}
                                            buttonDisplayName={'Add Gritzo Loyalty'}
                                            varData={{
                                                id: membershipProduct.id,
                                                name: membershipProduct.name,
                                                category: membershipProduct.category,
                                                offerPrice: membershipProduct.offerPrice,
                                                brName: membershipProduct.brName,
                                                img: membershipProduct.img,
                                                isConsultProduct: membershipProduct.isConsultProduct,
                                                leafNode: membershipProduct && membershipProduct.leafNode || '',
                                                secondaryCategory: membershipProduct && membershipProduct.secondaryCategory || '',
                                                seccategory: membershipProduct && membershipProduct.seccategory || ''
                                            }}
                                            wrapperClass={styles['add-to-cart-btn']}
                                            premium={true}
                                        />
                                        : <></>
                                }
                            </div>
                        </div>
                    </IntersectionObserver>
                    : <></>
            }
        </IntersectionObserver>
    )
}

export default LoyaltyInfoPopup;