// import './search-box.css'
import React, { useEffect, useRef, useState } from 'react';
import HK from 'factory/modules/HK'
import TopSearches from './TopSearches/index.jsx';
import RecentSearches from './RecentSearches';
import { connect } from 'react-redux';
import { searchActiveAction } from 'components/common/header/contains/Search/action.js'
import { captureCatPost, captureRcnt, concatName, savSrch, remSrch } from 'components/common/search/searchModule';
import { withRouter } from 'next/router';
import { searchStateAction } from 'components/common/header/action';
import BrandsAndCategories from 'components/common/header/contains/SearchBox/BrandsAndCategories'
import SuggestedProducts from './SuggestedProducts';
import NoResults from 'components/common/header/contains/SearchBox/NoResults'
import UniversalAnalytics from 'factory/modules/UniversalAnalytics';
import { __triggerInViewSearch, __triggerSearchClick } from './searchbox.service';
import { __localStorageDel, __localStorageGet, __localStorageSet } from 'factory/modules/HK_service.js';
import styles from './search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const SearchBox = ({ _searchActiveReducer, _searchActiveAction, closeButton, searchText, ...props }) => {

    const searchBoxRef = useRef()
    const inputRef = useRef()
    const getHeight = () => {
        return (
            _searchActiveReducer ?
                searchBoxRef.current ? searchBoxRef.current.scrollHeight : 120
                : 0
        )
    }

    const [searchInput, setSearchInput] = useState('');
    const [defaultSearchData, setDefaultSearchData] = useState(null)
    const [containerHeight, setContainerHeight] = useState(getHeight())
    const [searchCloseClass, setSearchCloseClass] = useState('')

    useEffect(() => {
        setContainerHeight(getHeight())
        focusInput()
    }, [])

    useEffect(() => {
        if (_searchActiveReducer) {
            setSearchCloseClass('search_close');
            fetchSearchData()
        }
        focusInput()
    }, [_searchActiveReducer])

    useEffect(() => {
        if (props.isSearchFormSubmit && props.searchFormEvent) {
            formSubmit(props.searchFormEvent)
        }
    }, [props.isSearchFormSubmit, props.searchFormEvent])

    useEffect(() => {
        setContainerHeight(getHeight())
    }, [defaultSearchData, _searchActiveReducer, searchInput])

    const focusInput = () => {
        setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
        }, 300)
    }

    const fetchSearchData = () => {
        const url = HK.loggedIn() ? HK.config.APIS.searchDefaultAfterLogin.replace('USER_ID', HK.loggedIn()) : HK.config.APIS.searchDefault;
        const reqObj = {
            url: url,
            method: 'GET'
        }
        // if (defaultSearchData === null)
        HK.ajaxCall(reqObj).then((res) => {
            setDefaultSearchData(res.data.results)
        }).catch((error) => {
            console.log(error)
        });
    }

    const trackSearchBarClick = () => {
        try {
            let eventName = HK.config.eventNames.SEARCH_TEXT_BAR || ""
            const eventData = {
                eventName: eventName,
                widgetName: eventName.toLowerCase() || "",
                itemName: eventName.toLowerCase() || ""
            }
            !searchInput && searchInput.length < 1 && HK.triggerComponentClickEvent(eventData)
        } catch (err) {
            console.log("TRACK_SEARCH_BAR_CLICK: " + err)
        }
    }

    useEffect(() => {
        if (searchText) {
            handleSearchInput(searchText)
        }
        else {

            handleSearchInput('')
        }
    }, [searchText])

    const handleSearchInput = (event) => {
        setSearchInput(event)
        if (event !== '') {
            let reqObj = {
                url: HK.config.APIS.searchWithParam + "?q=" + encodeURIComponent(event) + "&noRs=" + 10,
                method: "get"
            }
            HK.ajaxCall(reqObj).then((res) => {
                if (res && res.data && res.data.results && !res.data.results.exception) {
                    if (res.data.results.acItems) {
                        res.data.results.acItems = res.data.results.acItems.filter(item => item.navKey !== null).map((item, k) => {
                            let urlNavkey = item.navKey;
                            if (item.type === 3) {
                                let brnd = item.val.split('#');
                                urlNavkey = urlNavkey + '&brands=' + brnd[0];
                            }
                            item.url = HK.urlMaker(item.urlFragment, urlNavkey, ['brands-and-categories', (k + 1), item.nm.replace(/\s+/g, '-').replace(/&/g, 'n').toLowerCase(), 10]) + '&ac_sel=' + encodeURIComponent(item.nm.replace(/\s+/g, '-').replace(/&/g, 'n').toLowerCase());
                            return item;
                        });
                    }
                    if (res.data.results.acVariants) {
                        res.data.results.acVariants = res.data.results.acVariants.filter(item => item.navKey !== null).map((item, k) => {
                            item.url = HK.urlMaker(item.urlFragment, item.navKey, ['related-products', (k + 1), item.nm.replace(/\s+/g, '-').replace(/&/g, 'n').toLowerCase(), 10]) + '&ac_sel=' + encodeURIComponent(item.nm.replace(/\s+/g, '-').replace(/&/g, 'n').toLowerCase());
                            return item;
                        });
                    }
                    if (res.data.results.acPacks) {
                        res.data.results.acPacks = res.data.results.acPacks.filter(item => item.navKey !== null).map((item, k) => {
                            item.url = HK.urlMaker(item.urlFragment, item.navKey, ['related-products', (k + 1), searchInput, 3]) + '&ac_sel=' + encodeURIComponent(item.nm.replace(/\s+/g, '-').replace(/&/g, 'n').toLowerCase());
                            return item;
                        });
                    }
                    setDefaultSearchData(res.data.results)
                } else throw new Error('exception');
            }).catch((error) => {
                console.log(error)
                HK.errorPopup("Something went wrong, please try again.", 'Got: ' + error + '::' + reqObj.url);
HK.reportErrorToDashboard({
        stackTrace: new Error().stack,
        errorMessage: error
      });
            });
        } else {
            let reqObj;
            if (HK.loggedIn()) {
                reqObj = {
                    url: HK.config.APIS.searchDefaultAfterLogin.replace('USER_ID', HK.loggedIn()),
                    method: 'GET'
                }
            } else {
                reqObj = {
                    url: HK.config.APIS.searchDefault,
                    method: 'GET'
                }
            }
            HK.ajaxCall(reqObj).then((res) => {
                setDefaultSearchData(res.data.results)
            }).catch((error) => {
            });
        }
    }

    const formSubmit = (event) => {
        event.preventDefault();
        _searchActiveAction(false);
        if (searchInput !== "") {
            let kwd = concatName(searchInput);
            let eventdata = {
                pageType: window.pageType,
                keywordSearched: searchInput,
                keyword: kwd.replace(/\s\s+/g, ' '),
                zeroSearch: false,
            }
            let dataO = {
                eventName: "term",
                userAgent: navigator.userAgent,
                eventData: eventdata,
            };

            if (!HK.loggedIn()) {
                dataO.userId = 0;
            } else {
                dataO.userId = HK.loggedIn();
            }
            if (searchInput !== '') {
                savSrch(searchInput, null, null, "term", window.pageType, "", "", searchInput, searchInput, "", "", "");
                onRecentSearchEnter(searchInput)
            }

            var jsonObj = JSON.stringify(dataO);
            localStorage.setItem('srch-data', jsonObj);
            let urlFragment = "/search?txtQ=" + encodeURIComponent(eventdata.keywordSearched).replace(/%20/g, '+')
            UniversalAnalytics.SEARCH_CLICK_EVENT(searchInput, urlFragment, false);

            // localStorage.removeItem('searchAction');
            props._searchStateAction(eventdata.keywordSearched);
            // props.router.push(urlFragment);
            window.location.href = urlFragment;
        }
    }

    const onRecentSearchClick = (ele, e) => {

        const eventData = {
            eventType: "COMPONENT_CLICK",
            eventName: "SEARCH_ITEM",
            widgetName: "RECENT_SEARCH_ITEM",
            itemName: ele.term,
            landingUrl: ""
        }
        HK.triggerComponentClickEvent(eventData)

        let urlFragment = "/search?txtQ=" + encodeURIComponent(ele.term).replace(/%20/g, '+')
        UniversalAnalytics.SEARCH_CLICK_EVENT(searchInput, urlFragment, true);

        if (!HK.loggedIn()) {
            // localStorage.setItem('searchAction', 'Recent Searches');
            captureRcnt(ele.term);
        }
        // customDatalayer.bind(this, `ls_MB_Custom-searches_1`, `Custom Search`, "Recent Search", ele.term)
        //props._searchActiveAction(false)
        //props._searchStateAction(ele.term);

        _searchActiveAction(false)
        setSearchInput('')
    }

    const onTopSearchClick = (ele) => {
        // customDatalayer.bind(this, `ls_MB_Custom-searches_1`, `Custom Search`, "Top Searches", ele.term)
        UniversalAnalytics.SEARCH_CLICK_EVENT(searchInput, ele.url, true);
        _searchActiveAction(false)
        const clickStream = {
            widgetName: 'Menu Item Click',
            eventName: "MENU_ITEM_CLICK",
            itemName: ele && ele.name,
            landingUrl: ele && ele.url,
            price: ele && ele.mrp,
            offerPrice: ele && ele.offerPrice

        }
        HK.triggerComponentClickEvent(clickStream);
    }

    const onRecentSearchEnter = (searchInput) => {
        try {
            const eventData = {
                itemName: searchInput || "",
                keyword: searchInput || ""
            }
            __triggerSearchClick("enter", eventData)
            _searchActiveAction(false)
            setSearchInput('')
        } catch (err) {
            console.log("SEARCH-ENTER: " + err)
        }
    }

    const onSuggestedProductClick = (ele) => {
        UniversalAnalytics.SEARCH_CLICK_EVENT(searchInput, ele.url, true, ele.nm);
        _searchActiveAction(false)
        setSearchInput('')
        // const eventData = {
        //     widgetName: 'Search item Click',
        //     eventName: "SEARCH_ITEM_CLICK",
        //     itemName: ele && ele.nm,
        //     landingUrl: ele && ele.url,
        //     id:ele && ele.id,
        //     price: ele && ele.mrp,
        //     offerPrice: ele && ele.offer_pr

        // }

        const eventData = {
            widgetName: 'SUGGESTED_PRODUCTS',
            eventName: "SUGGESTED_PRODUCTS_CLICK",
            itemName: ele && ele.nm,
            landingUrl: ele && ele.url,
            id: ele && ele.id,
            price: ele && ele.mrp,
            offerPrice: ele && ele.offer_pr
        }
        HK.triggerComponentClickEvent(eventData);
    }

    const onBrandsCategoriesClick = (event, ele) => {
        // localStorage.setItem('searchAction', 'Brands & Categories');
        captureCatPost("ac_cat", '', window.pageType, ele.type, ele.val, ele.nm, searchInput, ele.nm.split(" in ")[1], ele.nm.split(" in ")[0] + " in");
        UniversalAnalytics.SEARCH_CLICK_EVENT(searchInput, ele.url, true);
        _searchActiveAction(false);
        setSearchInput('');
    }

    const clearRecentSearchData = (event, value) => {
        event.stopPropagation();
        event.preventDefault();

        if (!HK.loggedIn()) {
            remSrch();
            fetchSearchData();
        } else {
            let data = {
                userId: HK.loggedIn()
            }
            if (value) {
                data.clearType = "event"
                data.clearValue = value
            }
            let reqObj = {
                url: HK.config.APIS.clearRecentSearchData,
                method: "post",
                data
            }
            HK.ajaxCall(reqObj).then((res) => {
                fetchSearchData();
            }).catch((error) => {
                console.log(error)
                // HK.errorPopup("Something went wrong, please try again.", 'Got: ' + error + '::' + reqObj.url);
            });
        }
    }

    const getClass = () => _searchActiveReducer ? 'show' : ''

    const placeholder = HK.isMobile() ? 'Search For...e.g. Biozyme' : 'Search a product, a fitness goal, or a need..'

    const renderSearchResultsContainer = () => {
        if (defaultSearchData && defaultSearchData.acItems && defaultSearchData.acItems.length === 0 && defaultSearchData.acPacks && defaultSearchData.acPacks.length === 0) return <NoResults />
        return (
            <div className={convertToModule(styles, 'search-box__search-results')}>
                {/* Brand & Categories */}
                {
                    defaultSearchData && defaultSearchData.acItems && defaultSearchData.acItems.length > 0 &&
                    <BrandsAndCategories data={defaultSearchData.acItems} handleClick={onBrandsCategoriesClick} searchInput={searchInput} trackInViewSearch={trackInViewSearch} trackSearchClick={trackSearchClick} />
                }

                {/* Suggested Products */}
                {
                    defaultSearchData && defaultSearchData.acPacks && defaultSearchData.acPacks.length > 0 &&
                    <SuggestedProducts acPacks={defaultSearchData.acPacks} acVariants={defaultSearchData.acVariants} searchInput={searchInput} onSuggestedProductClick={onSuggestedProductClick} trackInViewSearch={trackInViewSearch} trackSearchClick={trackSearchClick} />
                }
            </div>
        )
    }

    const getRcntSrchData = () => {
        let recentSearches = __localStorageGet('rcnt-srch')
        try {
            if (recentSearches && recentSearches !== 'null') {
                if (typeof recentSearches === 'object' && recentSearches.length > 0) {
                    return recentSearches
                } else if (typeof recentSearches !== 'object' && JSON.parse(recentSearches).length > 0) {
                    return JSON.parse(recentSearches)
                }
            }
            else {
                return false
            }
        }catch(err){
            __localStorageDel('rcnt-srch')
            return false
        }
    }

    const renderSearchDefaultContainer = () => {
        return (
            <>
                {/* *** Top Searches *** */}
                {
                    defaultSearchData && defaultSearchData.trendingProducts && defaultSearchData.trendingProducts.length > 0 &&
                    <div className={convertToModule(styles, "search-box__inner")}>
                        <TopSearches data={defaultSearchData.trendingProducts} onTopSearchClick={onTopSearchClick} />
                    </div>
                }

                {/* *** Recent Searches *** */}
                {
                    defaultSearchData && defaultSearchData.recentSearches && defaultSearchData.recentSearches.length > 0 &&
                    <div className={convertToModule(styles, "search-box__inner")}>
                        <RecentSearches data={defaultSearchData.recentSearches} onRecentSearchClick={onRecentSearchClick} clearRecentSearchData={clearRecentSearchData} trackInViewSearch={trackInViewSearch} trackSearchClick={trackSearchClick} />
                    </div>
                }
                {
                    getRcntSrchData() &&
                    <div className={convertToModule(styles, "search-box__inner")}>
                        <RecentSearches data={getRcntSrchData().reverse()} onRecentSearchClick={onRecentSearchClick} clearRecentSearchData={clearRecentSearchData} trackInViewSearch={trackInViewSearch} trackSearchClick={trackSearchClick} />
                    </div>
                }
            </>
        )
    }

    const trackInViewSearch = (searchAction, data, position) => {
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    keyword: searchInput || "",
                    itemName: data.term || data.nm || "",
                    itemPosition: HK.getIntOrEmpty(position) || 1,
                    navKey: data.navKey || "",
                    id: data.id || ""
                }
                __triggerInViewSearch(searchAction, eventData)
            } catch (err) {
                console.log("TRACK-INVIEW-SEARCH: " + err)
            }
        }
    }

    const trackSearchClick = (searchAction, data, position) => {
        try {
            let keyword = searchAction === HK.config.searchActions.recentsearches ? data.term : searchInput
            const eventData = {
                keyword: keyword || "",
                itemName: data.term || data.nm || "",
                itemPosition: HK.getIntOrEmpty(position) || 1,
                navKey: data.navKey || "",
                id: data.id || ""
            }
            __triggerSearchClick(searchAction, eventData)
        } catch (err) {
            console.log("TRACK-SEARCH-CLICK: " + err)
        }
    }

    return (
        <div
            className={convertToModule(styles, `search-box__parent ${getClass()} ${searchCloseClass}`)}
            ref={searchBoxRef}
        // style={{ height: `${containerHeight}px` }}
        >
            <div onClick={() => _searchActiveAction(false)} className={convertToModule(styles, `background-overlay`)}></div>
            <div onClick={() => _searchActiveAction(false)} className={convertToModule(styles, `background-overlay-1`)}></div>
            <div className={convertToModule(styles, 'search-box__container')}>
                {/* {
                    closeButton &&
                    <img src={HK.getImage('/header/searchbox/close-search-mweb.svg')} alt="close button" className={convertToModule(styles, 'close-btn')} onClick={()=> _searchActiveAction(false)}/>
                } */}

                {
                    HK.isMobile() ?
                        <form className={convertToModule(styles, "search-box__input")} onSubmit={formSubmit}>
                            <svg onClick={() => _searchActiveAction(false)} className={convertToModule(styles, "back-button")} fill="#4e4e4e" height="24px" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                            </svg>
                            <input type="text" ref={inputRef} placeholder={placeholder} onChange={(e) => props.handleTextChange(e)} value={searchInput} id="search_text" onClick={trackSearchBarClick} />
                            {/* {HK.isMobile() ? <img src={HK.getImage('/header/searchbox/icon-serch.svg')} alt="icon-serch.svg" />:<img src={HK.getImage('/header/searchbox/search.svg')} alt="" />} */}
                        </form>
                        : ''
                }
                {
                    searchInput && searchInput.length > 0 ?
                        renderSearchResultsContainer() :
                        renderSearchDefaultContainer()
                }
            </div>

        </div>
    );
}
function mapStateToProps(state) {
    return {
        _searchActiveReducer: state.searchActiveReducer,
    };
}

const mapDispatchToProps = {
    _searchStateAction: searchStateAction,
    _searchActiveAction: searchActiveAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchBox));