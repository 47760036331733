import React from 'react';
import RecentSearchItem from './RecentSearchItem';
import styles from '../search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const RecentSearches = ({ data, ...props }) => {
    if (!data) return
    return (
        <div className={convertToModule(styles, "search-box__RS")}>
            <div className={convertToModule(styles, "search-box__title")}>
                recent searches
                {/* <span onClick={props.clearRecentSearchData}>Clear</span> */}
            </div>
            <div className={convertToModule(styles, "search-box__RS__items")}>
                {
                    data.map((ele, index) => {
                        return <RecentSearchItem key={index} ele={ele} {...props} itemPosition={++index}/>
                    })
                }
            </div>
        </div>
    );
}

export default RecentSearches;