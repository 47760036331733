import React, { Component } from 'react';
//import HeaderTopTrip from './HeaderTopTrip';
import HeaderTopBar from './HeaderTopBar/index.jsx';
// import HeaderTopBar from './HeaderTopBar';
// import HeaderMenu from './HeaderMenu';
import ReferAndEarnTop from 'utility/ReferAndEarnTop';
import StaticSearch from 'components/common/header/contains/StaticSearch';
// import Itrack from 'utility/Itrack'
// redux imports
import HK from 'factory/modules/HK';
import { withRouter } from 'next/router';
import { capturePacVarPost, captureCatPost, remSrch, savSrch, getVrntIdFrmUrl, concatName, captureRcnt } from 'components/common/search/searchModule';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { searchDataAction } from 'components/common/header/desktop/action';
import { searchStateAction } from 'components/common/header/action';
class DesktopHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stickyHeaderFixClass: "",
            searchData: null,
            isShowRE: false,
            searchStr: '',
        }
        this.handleScroll = this.handleScroll.bind(this);
        this.searchTxtHighlighter = this.searchTxtHighlighter.bind(this);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
        if (window.location.href.indexOf("utm_source=invitereferrals") > 0) {
            this.setState({
                isShowRE: true
            })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }
    handleScroll(event) {
        if (window.scrollY > 109) {
            this.setState({
                stickyHeaderFixClass: "sticky-header"
            })
        }
        else if (this.state.stickyHeaderFixClass !== "") {
            this.setState({
                stickyHeaderFixClass: ""
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.searchDataReducer !== null)
            this.setState({
                searchData: nextProps.searchDataReducer.result,
                searchStr: nextProps.searchDataReducer.searchStr
            })
    }
    closeReferAndEarnTop() {
        localStorage.setItem('refer_show_header', false);
        this.setState({
            isShowRE: false
        })
    }
    searchTxtHighlighter(str) {
        let text = str.toLowerCase();
        let search = this.state.searchStr.toLowerCase();
        let newStr = text.replace(search, "<span class='fnt-bld'>" + search + "</span>");
        // newStr = newStr.replace(new RegExp('in','g'),"in<span className='fnt-blue'>"+search+"</span>");
        newStr = newStr.split(' in ').join(" in <span class='fnt-blue'>") + "</span>";
        return newStr;
    }
    clearRecentSearchData(value) {
        var reqObj = {
            url: HK.config.APIS.clearRecentSearchData,
            method: "post",
            data: {
                userId: HK.loggedIn(),
                clearType: "event",
                clearValue: value
            }
        }
        HK.ajaxCall(reqObj).then((res) => {
            if (HK.loggedIn()) {
                reqObj = {
                    url: HK.config.APIS.searchDefaultAfterLogin.replace('USER_ID', HK.loggedIn()),
                    method: 'GET'
                }
            } else {
                reqObj = {
                    url: HK.config.APIS.searchDefault,
                    method: 'GET'
                }
            }
            HK.ajaxCall(reqObj).then((res) => {
                let reqObj;
                if (HK.loggedIn()) {
                    reqObj = {
                        url: HK.config.APIS.searchDefaultAfterLogin.replace('USER_ID', HK.loggedIn()),
                        method: 'GET'
                    }
                } else {
                    reqObj = {
                        url: HK.config.APIS.searchDefault,
                        method: 'GET'
                    }
                }
                HK.ajaxCall(reqObj).then((res) => {
                    if (!res.data.results.exception)
                        this.props._searchDataAction({
                            result: res.data.results,
                            searchStr: this.state.searchInput
                        })
                    else throw new Error('exception')
                }).catch((error) => {
                    HK.errorPopup('Someting went wrong, please try again.', 'Got: ' + error + '::' + reqObj.url);
                    HK.reportErrorToDashboard({
                        stackTrace: new Error().stack,
                        errorMessage: error
                    });
                });
            }).catch((error) => {
                HK.errorPopup('Something went wrong, please try again.', 'Got: ' + error + '::' + reqObj.url);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: error
                });
            });
        });
    }
    rcntSearchtrack(txt) {
        var dataO = {};
        var eventdata = {};
        var kwd = this.state.searchInput;
        kwd = concatName(kwd);
        //if(kwd.indexOf('Rs.')>-1){kwd=$.trim(kwd.substr(0,kwd.lastIndexOf('Rs')));}else{kwd=$.trim(kwd);}
        eventdata.pageType = window.pageType;
        eventdata.keywordSearched = this.state.searchInput;
        eventdata.keyword = kwd.replace(/\s\s+/g, ' ');
        eventdata.zeroSearch = false;
        dataO.eventName = "term";
        if (!HK.loggedIn()) {
            dataO.userId = 0;
        }
        if (this.state.searchInput !== '') {
            savSrch(this.state.searchInput, null, null, "term", window.pageType, "", "", this.state.searchInput, this.state.searchInput, "", "", "");
        } else {
            dataO.userId = HK.loggedIn();
        }
        dataO.userAgent = navigator.userAgent;
        dataO.eventData = eventdata;
        var jsonObj = JSON.stringify(dataO);
        localStorage.setItem('srch-data', jsonObj);
        localStorage.setItem('searchAction', 'Recent Searches');
        // var searchQuery = encodeURIComponent(txt).replace(/%20/g, " ").replace(/%26/g, "&").replace(/%2C/g, ",").replace(/%2B/g, "+").replace(/%3A/g, ":").replace(/%25/g, "%");
        // searchQuery=searchQuery+"?"+jsonObj;
    }
    // renderSearchItem(ele, i) {
    //     return (
    //         <div className="trnd-prod" key={i}>
    //             <a className="tprod" href={ele.url + '&' + HK.itracker('trending-products', (i + 1), ele.name.toLowerCase(), 10) + '&ac_sel=' + encodeURIComponent(ele.name)}>
    //                 <img className="prodimg" src={ele.imageUrl} alt={ele.imageUrl} style={{ height: "115px", width: "115px" }} />
    //                 <br />
    //                 <span className="prodname" style={{"WebkitBoxOrient":" vertical"}}>{ele.name}</span><br />
    //                 <span className="prodmrp" >Rs.{ele.offerPrice}</span>
    //             </a>
    //         </div>
    //     )
    // }
    render() {
        return (
            <div>
                <div className={"desktop-header " + this.state.stickyHeaderFixClass}>
                    {
                        this.state.isShowRE &&
                        <ReferAndEarnTop
                            closeReferAndEarnTop={this.closeReferAndEarnTop.bind(this)}
                        />
                    }
                    <HeaderTopBar isMobile={this.props.isMobile} cartHandler={this.props.cartHandler} profileHandler={this.props.profileHandler} {...this.props} />
                    {this.props.searchOverlayReducer && <div className="height-auto" id="hk-search-box-result" onClick={(event) => { event.stopPropagation(); }}>
                        <StaticSearch></StaticSearch>
                    </div>
                    }
                </div>
            </div>
        );
    }
}
// Take value from store
function mapStateToProps(state) {
    return {
        searchOverlayReducer: state.searchOverlayReducer,
        searchDataReducer: state.searchDataReducer,
    };
}
// Store value
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _searchDataAction: searchDataAction,
        _searchStateAction: searchStateAction
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DesktopHeader));