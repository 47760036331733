import HK from "factory/modules/HK";

export function getStripAttr(stripData) {
    const defaultBgColor = "#272b65";
    const defaultTextColor = "#FFF";
    const obj = {};
    let bgC, color, showStrip = false;
    let item = stripData && stripData.scContent && stripData.scContent.length > 0 &&
        stripData.scContent[0];
    item && item.attributeArea &&
        item.attributeArea.length > 0 &&
        item.attributeArea.forEach(attr => {
            if (attr && attr.value) {
                if (attr.name === "bgColor") {
                    bgC = attr.value;
                } else if (attr.name === "textColor") {
                    color = attr.value;
                } else if (attr.name === "pageType") {
                    showStrip = isPageTypeExist(attr.value);
                }
            }
        });
    obj.bg = { "backgroundColor": bgC || defaultBgColor };
    obj.text = { "color": color || defaultTextColor };
    obj.show = showStrip;
    return obj;
}

export function isPageTypeExist(pageTypeString) {
    const pageType = window.pageType;
    const pageTypes = pageTypeString && pageTypeString.split(",");
    let finalBool = false;
    pageTypes &&
        pageTypes.length > 0 &&
        pageTypes.forEach(page => page.trim() === pageType ? finalBool = true : null);
    return finalBool;
}

export const getTagBasedOnString = (strip, strip2) => {
    const screenWidth = screen && screen.width;
    let reference = HK.isMobile() ? screenWidth / 6 : screenWidth / 7;
    const characters = Math.round(Math.floor(reference));
    if (strip && strip.desc && strip.desc.length > 0) {
        if (strip && strip.desc && strip.desc.length > characters) {
            return "marquee";
        } else return 'p';
    } else {
        if (strip2 && strip2.length > characters) {
            return 'marquee';
        } else return "p"
    }
};

export const increaseHeightBasedOnPromotionalStrip = (data, pgType) => {
    const pageType = pgType || window.pageType;
    const strip1 = data && data.menuPromotionalDataDto;
    const strip2 = data && data.menuPromotionalData;
    let Layout;
    Layout = document.querySelector("#header-layout-height");
    let spacePlus = getStripAttr(strip1);
    if ((spacePlus && spacePlus.show) || strip2 && pageType === "home") {
        Layout ? Layout.style.minHeight = "120px" : "";
    }
    else {
        Layout ? Layout.style.minHeight = "76px" : "";
    }
}
