import React, { Component } from 'react';
import SSRHead from 'components/SSR/SSRHead';
import dynamic from 'next/dynamic';
import HeaderLayout from './HeaderLayout';
// import FooterLayout from './FooterLayout';
import FourOFourPage from 'utility/templates/ErrorPage/404Page';
import HK from 'factory/modules/HK';
import { connect } from 'react-redux';
import LoaderLayout from './LoaderLayout';
import RudderStackAnalytics from 'factory/modules/RudderStackAnalytics';
const Utility = dynamic(() => import('components/common/misc/utility'), { ssr: false });

class PageLayout extends Component {
  constructor(props) {
    super(props);
    if (typeof window != 'undefined') {
      window.pageType = props.pageType;
      HK.updateScreenName();
      // HK.isMobile() && HK.chatIcon(false);
      // HK.isMobile() && HK.showOpenInAppBar(false);
    }
  }

  componentDidMount = () => {
    RudderStackAnalytics.SEND_EVENT("page");
  }

  getUniqueKey = () => {
    let result = "";
    const props = this.props;
    const _queryParam = props.query;
    const _requestUrl = props.router && props.router.asPath;
    const _pageType = props.pageType;
    let _key = "";
    const BLACKLIST_PAGES = process.env.NEXT_PUBLIC_CRITTERS_BLACKLIST_URLS;
    const CACHE_ENABLED = process.env.NEXT_PUBLIC_CRITTERS_CACHE_ENABLE;

    // console.log("QueryParam: ", _queryParam);
    // console.log("RequestURL: ", _requestUrl);
    if (!CACHE_ENABLED) {
      return "";
    }
    if (BLACKLIST_PAGES && _requestUrl) {
      const _pagesArr = BLACKLIST_PAGES.split(",");
      const _requestUrlMinimized = _requestUrl.split("?")[0];
      if (_pagesArr.includes(_requestUrlMinimized)) {
        return "";
      }
    }
    try {
      if (_pageType) {
        _key = _pageType.replace(/ /g, '_');
      } else if (_requestUrl) {
        const _requestUrlMinimized = _requestUrl.split("?")[0];
        if (_requestUrlMinimized === "/") {
          _key = 'home';
        } else {
          _key = _requestUrlMinimized.replace(/\//g, '_');
        }
      } else {
        return "";
      }
      if (_queryParam && _queryParam["PURGE_ALL_CRITICAL_CACHE"] === "true") {
        return "PURGE_ALL_CRITICAL_CACHE";
      }
      if (_queryParam && _queryParam["PURGE_CRITICAL_CACHE"] === "true") {
        _key = "PURGE_CRITICAL_CACHE_" + _key;
      }
      result = `${_key}_${props.isMobile ? 'mobile' : 'desktop'}_${(props.serverIsLoggedin || props.isLoggedin) ? 'logged_in' : 'logged_out'}`
    } catch (error) {
      console.log("ERROR GETTING UNIQUE KEY: ", error);
      result = "";
    }
    // console.log("UNIQUE_KEY: ", result);
    return result;
  }

  renderOGSchema = () => {
    return (
      <>
        <div itemscope="" itemType="https://schema.org/Organization" style={{ display: 'none' }}>
          <span itemProp="name">Gritzo</span>
          <link itemProp="url" href="https://www.Gritzo.com" />
          <img itemprop="image" src={HK.getImage('/icons/mb-logo.svg')} alt="mb logo"></img>
          <div itemscope="" itemProp="contactPoint" itemscop="" itemType="http://schema.org/ContactPoint">
            <meta itemProp="telephone" content="+91 92 054 87 429" />
            <meta itemProp="contactType" content="Customer service" />
          </div>
          <div>
            <a itemProp="sameAs" href="https://www.facebook.com/GritzoIndia">Facebook</a>
            <a itemProp="sameAs" href="https://www.instagram.com/gritzoindia">Instagram</a>
            <a itemProp="sameAs" href="https://twitter.com/Gritzo">Twitter</a>
            <a itemProp="sameAs" href="https://www.youtube.com/channel/UChKtlpPT6MX2iDfZ586BHxg">Youtube</a>
          </div>
          <div itemProp="address" itemscope="" itemType="https://schema.org/PostalAddress">
            <span itemProp="streetAddress">
            The Presidency Tower, Tower-B, 2nd Floor, 46/4, Mehrauli Rd opp. government girls college, Anamika Enclave, Sector 14, Gurugram, Haryana
            </span>
            <span itemProp="addressRegion">India</span>
            <span itemProp="postalCode">122001</span>
            <span itemProp="telephone">+91 92 054 87 429</span>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { routeLoading, pageName, isMobile, show404, children } = this.props;
    return (<div className="main-container-wrapper page_layout_nxt" data-pagetype={this.getUniqueKey()}>
      {!show404 && <SSRHead {...this.props} />}
      {/* <main> */}
      {!this.props.noHeader ? <HeaderLayout isMobile={isMobile} {...this.props} /> : ''}
      {/* ################### 
                  do not change id const_web_main_container required for SEO caching 
                  ########################## */}
      <div id='const_web_main_container' className={!isMobile ? "page-layout-desktop react-page-container" : "page-layout-mob react-page-container"}>
        {this.renderOGSchema()}
        {
          show404
            ?
            <FourOFourPage />
            :
            <LoaderLayout
              pageName={pageName}
              isMobile={isMobile}
              routeLoading={routeLoading}
            >
              {
                children
              }
            </LoaderLayout>
        }
      </div>
      {!this.props.noFooter ? <Utility hideFooter={this.props.hideFooter} /> : ''}
      {/* </main> */}
      {/* <footer>
                <FooterLayout isMobile={this.props.isMobile} />
            </footer> */}
    </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggedin: state.isLogedInReducer
  };
}

export default connect(mapStateToProps, null)(PageLayout);