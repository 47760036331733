import React from 'react';
import Link from 'components/Assets/LinkHref';
import HK from 'factory/modules/HK';
import styles from '../../../../static/css/header.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const StaticSearch = (props) => {
    const updateDatalayer = (eventAction, eventLabel) => {
        let data = {
            'event': 'Is_MB_Custom-searches_1',
            'eventCategory': 'Custom Search',
            'eventAction': eventAction,
            'eventLabel': eventLabel,
        }
        HK._updateDataLayer(data);
    }
    return (
        <React.Fragment>
            <div className={convertToModule(styles, "text-sugg height-auto")}>
                <div className={convertToModule(styles, "srch-box-topic")}>Top Searches</div>
                <div className={convertToModule(styles, "srch-box-items")}>
                    <ul className={convertToModule(styles, "srch-box-items-list")}>
                        <li onClick={updateDatalayer.bind(this, `Top Searches`, "Whey Gold Protein")}>
                            <Link onClick={props.menuHandler} to={'/sv/muscleblaze-whey-gold/SP-43113?navKey=VRNT-78029'}>Whey Gold Protein</Link>
                        </li>
                        <li onClick={updateDatalayer.bind(this, `Top Searches`, "100% Whey Protein")}>
                            <Link onClick={props.menuHandler} to={'/sv/muscleblaze-100-whey-protein-supplement-powder-with-digestive-enzyme/SP-33626?navKey=VRNT-72753'}>
                                100% Whey Protein</Link>
                        </li>
                        <li onClick={updateDatalayer.bind(this, `Top Searches`, "Mass Gainer XXL")}>
                            <Link onClick={props.menuHandler} to={'/sv/muscleblaze-mass-gainer-xxl-with-complex-carbs-and-proteins-in-3:1-ratio/SP-33848?navKey=VRNT-72747'}>
                                Mass Gainer XXL</Link>
                        </li>
                        <li onClick={updateDatalayer.bind(this, `Top Searches`, "Raw Whey Protein")}>
                            <Link onClick={props.menuHandler} to={'/sv/muscleblaze-80-raw-whey-protein-supplement-powder/SP-40454?navKey=VRNT-73781'}>
                                Raw Whey Protein</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}
export default StaticSearch;