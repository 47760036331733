import React from 'react';
import Button from 'Atom/Button.js'
import { CloseIcon } from 'Atom/UI/Icons';
import Link from 'components/Assets/LinkHref';
import { withRouter } from 'next/router';
import Analytics from 'factory/modules/Analytics';
import { isLogedInAction, isAssumeAction } from 'factory/store/actions/';
import { isLoginPopupAction } from 'components/common/login/action';
import { CARTAction } from 'components/common/header/action.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Itrack from 'utility/Itrack';
import HK from 'factory/modules/HK';
import VerloopSDK from 'factory/modules/Verloop';
import { __triggerLogoutEvent } from 'factory/modules/HK_service';
class ProfileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            _HkCash: null,
            userData: localStorage.getItem('userData') && localStorage.getItem('userData') !== "null" && JSON.parse(localStorage.getItem('userData')).firstName
        }
    }
    componentDidMount() {
        let reqObj = {
            url: HK.config.APIS.myAccount.userHkCash,
            method: "get",

        }
        HK.ajaxCall(reqObj).then((res) => {
            if (!res.data.results.exception) {
                this.setState({
                    _HkCash: res.data.results,
                })
            } else {
                HK.errorPopup("Something went wrong, please try again");
HK.reportErrorToDashboard({
        stackTrace: new Error().stack,
      });
            }
        }).catch((err) => {
            console.log(err);
            // HK.errorPopup("Something went wrong, please try again");
        })
    }
    logout(event) {
        let $this = this;
        HK.ajaxCall({
            url: HK.config.APIS.userFlow.logout,
            method: "GET"
        }).then(res => {
            this.props.clickHandler();
            __triggerLogoutEvent();
            $this.props._isLogedInAction(false);
            $this.props._isAssumeAction(false);
            $this.props._CARTAction({
                cartVariantSummaryList: [],
                noItemsInCart: 0
            });
        }).catch(err => { });
    }
    redirectToHome = () => {
        VerloopSDK.logout();
        let loggedInRequiredPages = [
            "cart", "selectAddress", "selectPayment", "paymentSuccess", HK.config.pageTypes.mbDataCapture, HK.config.pageTypes.gritzo.growthTrack
        ]
        if (loggedInRequiredPages.indexOf(window.pageType) > -1) {
            // this.props.history.push("/");
            window.location.href = "/";
        }
    }
    updateDataLayer=(eventAction)=>{
        let data = {
            'event': "Is_MB_UserProfiles",
            'eventCategory': "User Profile",
            'eventAction': eventAction,
            'eventLabel': "",
        }
        HK._setDataLayer(data);
    }
    
    render() {
        return (
            <React.Fragment>
                {this.props.menuIndex == 'profilemenu' && <div className={(!HK.isMobile() ? "white " : "black ") + 'menu-back-overlay'} onClick={this.props.clickHandler}></div>}
                <div className={(this.props.menuIndex == 'profilemenu' ? "display-menu " : '') + 'vertical-menu-wrap profile-menu fromRight'}>
                    <div className="vertical-menu-inner">
                        <div className="vertical-menu-header">
                            <span className="close-btn" onClick={this.props.clickHandler}>
                                <CloseIcon></CloseIcon>
                            </span>
                        </div>
                        <div className="vertical-menu-content">
                            <div className="menu-actual-content">
                                <p className="welcome-msg">Welcome back</p>
                                <h2 className="user-name">{this.state.userData}</h2>
                                <div>
                                    <ul className="menu-item">
                                        <li onClick={this.updateDataLayer.bind(this,'Profile')}>
                                            <Link onClick={this.props.clickHandler} to='/account/MyAccount?pre=&tabId=1'>Profile</Link>
                                        </li>
                                        <li onClick={this.updateDataLayer.bind(this,'My Orders')}>
                                            <Link onClick={this.props.clickHandler} to="/account/MyAccount?pre=&tabId=5">My Orders</Link>
                                        </li>
                                        <li onClick={this.updateDataLayer.bind(this,'Gritzo Cash')}>
                                            <Itrack url="/reward/RewardPoint.action?pre=&tabId=7" classname="hkcash_account_header" nam='Gritzo Cash' childOf='account-dropdown-section' isFullUrl={true}>
                                                Gritzo Cash {this.state._HkCash && this.state._HkCash.tot_redeem_pts > 0 ? <span className="hk_cash">({Math.floor(this.state._HkCash.tot_redeem_pts)})</span> : '(0)'}
                                            </Itrack>

                                        </li>
                                        <li onClick={this.updateDataLayer.bind(this,'My Address')}>
                                            <Link onClick={this.props.clickHandler} to='/account/MyAccountAddress?pre=&tabId=4'>MY ADDRESSES</Link>
                                        </li>
                                        <li onClick={this.updateDataLayer.bind(this,'Product Recommendation')}>
                                            <Link onClick={this.props.clickHandler} to='/product-recommendation'>Product Recommendation</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="vertical-menu-footer">
                            <Button type="button" buttonClick={this.logout.bind(this)} btnClasses='btn black2 sign-out-btn' buttonText="Sign Out"></Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        isLoggedin: state.isLogedInReducer,
        CART: state.CARTReducer
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _isLogedInAction: isLogedInAction,
        _isLoginPopupAction: isLoginPopupAction,
        _CARTAction: CARTAction,
        _isAssumeAction: isAssumeAction
    }, dispatch)
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
);