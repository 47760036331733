import React, { useEffect } from 'react';
import Itrack from 'utility/Itrack';
import styles from './menuStrip.module.scss';
import { getStripAttr, getTagBasedOnString, increaseHeightBasedOnPromotionalStrip, isPageTypeExist } from './menuStripConfig';
import { connect } from 'react-redux';

const MenuStrip = ({ promotionData, ...props }) => {
    const strip = promotionData && promotionData.menuPromotionalDataDto;
    const strip2 = promotionData && promotionData.menuPromotionalData;
    if (!strip && !strip2) return "";
    const attr = getStripAttr(strip);
    const CustomTag = strip && strip.scUrl ? Itrack : "div";
    const CustomInnerTag = getTagBasedOnString(strip, strip2);

    useEffect(() => {
        if(props._pageTypeReducer) increaseHeightBasedOnPromotionalStrip(promotionData, props._pageTypeReducer);
    }, [props._pageTypeReducer])

    return (
        <>
            {strip && strip.desc && attr.show ?
                <CustomTag isFullUrl={true} isLink={false} url={strip.scUrl} classname={styles['informationStrip']} className={styles['informationStrip']} style={attr.bg}>
                    <CustomInnerTag style={attr.text}>{strip && strip.desc}</CustomInnerTag>
                </CustomTag> : strip2 && isPageTypeExist("home") ? <div className={styles['informationStrip']}><CustomInnerTag>{strip2}</CustomInnerTag></div> : ""
            }
        </>
    );
};

function mapStateToProps(state) {
    return {
        _pageTypeReducer: state.pageTypeReducer,
    }
}

export default connect(mapStateToProps, null)(MenuStrip);