import React, { Component } from 'react';
import HeaderTop from '../HeaderTop/index.jsx';
// import HeaderTop from '../HeaderTop';
import HK from 'factory/modules/HK';
// import MobileMenu from 'components/common/header/MobileMenu.js';
// store imports
import { MenuNodeAction } from 'components/common/header/action';
import { isLoginPopupAction } from 'components/common/login/action';
import { mobSearchOpenAction, mobMenuOpenAction } from 'components/common/header/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import styles from '../../../../static/css/header.module.scss'
import { convertToModule } from 'factory/utils/clientUtils.js';
import { headerPromotionalTextAction, menuPromotionalDataAction } from 'components/common/header/desktop/action.js'

const MobileMenu = dynamic(() => import('components/common/header/MobileMenu.js'), { ssr: false });
class MobileHeader extends Component {
    constructor() {
        super();
        this.state = {
            displayMenuClass: '',
            isHeaderFix: false,
            isSlided: this.props !== undefined && this.props._mobSearchOpenReducer,
            searchInput: "",
            voiceWrapperStyle: "",
            voiceContainerStyle: "",
            listeningStyleClass: "listening-style",
            voiceTitle: "Listening...",
            switchTitle: true,
            isLoginPopupOpen: false,
            isloadMenu: false,
            isUserMenu: false,
            // menuDataMobile:null
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
        if (this.props._MenuData && this.props._MenuData.tpMenuNode && this.props._MenuData.tpMenuNode.length > 0) {
            return;
        }
        HK.ajaxCall({
            url: HK.config.APIS.menu.headerMenu,
            method: "GET"
        }).then(res => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                this.props._MenuNodeAction(res.data.results);
                this.props._headerPromotionalTextAction(res.data.results.menuPromotionalData);
                this.props._menuPromotionalDataAction(res.data.results);
                // this.setState({menuDataMobile:res.data.results});
            }
        }).catch(err => { });
    }
    componentWillUnmount() {
        // window.removeEventListener('scroll', this.handleScroll.bind(this));
        HK.stopScroll(false);

    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps._mobMenuOpenReducer) {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    }
    handleScroll(event) {
        if (window.scrollY > 56) {
            if (!this.state.isHeaderFix) {
                this.setState({
                    isHeaderFix: true
                })
            }
        }
        else {
            if (this.state.isHeaderFix) {
                this.setState({
                    isHeaderFix: false
                })
            }
        }
    }
    toggleSearch() {
        if (this.state.isSlided) {
            this.setState({
                isSlided: false
            });
            this.props._mobSearchOpenAction(false);
        } else {
            this.setState({
                isSlided: true
            });
            this.props._mobSearchOpenAction(true);
        }
    }
    showMenu() {
        this.setState({
            displayMenuClass: "display-menu",
            isloadMenu: true
        });
        HK.stopScroll(true);
        this.props._mobMenuOpenAction(true);
        this.props.HideFooterMenu();
        this.slideScreen(true);
    }
    hideMenu() {
        this.setState({
            displayMenuClass: "",
            isloadMenu: false,
            isUserMenu: false
        });
        HK.stopScroll(false);
        this.props._mobMenuOpenAction(false);
        this.slideScreen(false);
    }
    slideScreen = (slide) => {
        let hkAppArr = document.getElementsByClassName("gritzo-app");
        if (hkAppArr && hkAppArr.length > 0) {
            let hkApp = hkAppArr[0];
            slide ? hkApp.classList.add('hk-app-slide') : hkApp.classList.remove('hk-app-slide');
        }
    }
    openLoginPopup() {
        this.props._isLoginPopupAction({ isShow: true, activeTab: "login" });
    }
    closeLoginPopup() {
        this.setState({
            isLoginPopupOpen: false
        })
    }
    showUserMenu = () => {
        this.setState({
            isUserMenu: true
        });
        this.showMenu();
    }
    render() {
        return (
            <div className={convertToModule(styles, "main-header-wrapper")}>
                {!HK.fromApp() && <HeaderTop cartHandler={this.props.cartHandler}
                    toggleSearch={this.toggleSearch.bind(this)}
                    showMenu={this.showMenu.bind(this)}
                    openLoginPopup={this.openLoginPopup.bind(this)}
                    isSlided={this.state.isSlided}
                    isFixed={this.state.isHeaderFix}
                    showUserMenu={this.showUserMenu}
                    HideFooterMenu={this.props.HideFooterMenu}
                    isMobile={this.props.isMobile}
                    isloadMenu={this.state.isloadMenu}
                    hideMenu={this.hideMenu.bind(this)}
                />}
                {
                    this.state.isloadMenu &&
                    <MobileMenu
                        hideMenu={this.hideMenu.bind(this)}
                    />
                }

            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        _mobSearchOpenReducer: state.mobSearchOpenReducer,
        _mobMenuOpenReducer: state.mobMenuOpenReducer,
        _pageType: state.pageTypeReducer,
        _MenuData: state.MenuReducer.MenuData,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _isLoginPopupAction: isLoginPopupAction,
        _mobSearchOpenAction: mobSearchOpenAction,
        _mobMenuOpenAction: mobMenuOpenAction,
        _MenuNodeAction: MenuNodeAction,
        _headerPromotionalTextAction: headerPromotionalTextAction,
        _menuPromotionalDataAction: menuPromotionalDataAction
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);