export const searchOverlayAction = (data) => {
	return {
		type: "SEARCH_OVERLAY_ACTION",
		payload: data
	}
}

export const searchDataAction = (data) => {
	return {
		type: "SEARCH_DATA",
		payload: data
	}
}

export const searchInputAction = (data) => {
	return {
		type: "SEARCH_INPUT",
		payload: data
	}
}

export const menuAction = (menuData) => {
	return {
		type: "footerMenu",
		payload: menuData
	}
}

export const headerPromotionalTextAction = (data) => {
	return {
		type: "HEADER_PROMOTIONAL_TEXT",
		payload: data
	}
}

export const menuPromotionalDataAction = (data) =>{
	return {
		type: "HEADER_MENU_STRIP",
		payload: data
	}
}