import React, { Component } from 'react';
import CatL2Desktop from 'utility/templates/skeletons/CatL2Desktop';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';

class LoaderLayout extends Component {

  renderLoader = () => {
    const { pageName, isMobile, children } = this.props;
    let skeletonRender;
    switch (pageName) {
      case "pdp":
        skeletonRender = <SkeletonPattern pattern={isMobile ? [10] : [9]} />
        break;
      case "plp":
        skeletonRender = isMobile ? <SkeletonPattern pattern={[2, 8]} /> : <CatL2Desktop />
        break;
      default:
        skeletonRender = <>{children}<div className="hk-loader-default"><div></div></div></>
        break;
    }
    return skeletonRender;
  }

  render() {
    const { routeLoading, children } = this.props;
    return (
      routeLoading ? this.renderLoader() : children
    )
  }
}

export default LoaderLayout;