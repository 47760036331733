import HK from 'factory/modules/HK'
import Analytics from 'factory/modules/Analytics';

export const __getEventWidgetData = (searchAction) => {
    try{
        let widgetData = {}
        switch(searchAction){
            case HK.config.searchActions.recentsearches:
                widgetData = {
                    eventName: HK.config.eventNames.RECENT_SEARCHES || "",
                    widgetName: HK.config.eventNames.RECENT_SEARCHES.toLowerCase() || ""
                }
                break;
            case HK.config.searchActions.suggestedproducts:
                widgetData = {
                    eventName: HK.config.eventNames.SUGGESTED_PRODUCTS || "",
                    widgetName: HK.config.eventNames.SUGGESTED_PRODUCTS.toLowerCase() || ""
                }
                break;
            case HK.config.searchActions.brandsandcategories:
                widgetData = {
                    eventName: HK.config.eventNames.BRANDS_AND_CATEGORIES || "",
                    widgetName: HK.config.eventNames.BRANDS_AND_CATEGORIES.toLowerCase() || ""
                }
                break;
            case HK.config.searchActions.enter:
                widgetData = {
                    eventName: HK.config.eventName.COMPONENT_PRESS_ENTER || "",
                    widgetName: HK.config.eventName.COMPONENT_PRESS_ENTER.toLowerCase() || ""
                }
                break;
            default: break;
        }
        return widgetData
    }catch(err){
        console.log("GET-EVENT-WIDGET-DATA-SERVICE: "+err)
    }
}

export const __triggerInViewSearch = (searchAction, data) => {
    try{
        let searchActionData = searchAction ? {"searchAction": searchAction} : {}
        let widgetData = searchAction ? __getEventWidgetData(searchAction) : {}
        const eventData = {
            ...searchActionData,
            ...widgetData,
            ...data
        }
        HK.triggerComponentViewEvent(eventData)
    }catch(err){
        console.log("TRIGGER-INVIEW-SEARCH-SERVICE: "+err)
    }
}

export const __triggerSearchClick = (searchAction, data) => {
    try{
        let searchActionData = searchAction ? {"searchAction": searchAction} : {}
        let widgetData = searchAction ? __getEventWidgetData(searchAction) : {}
        const eventData = {
            ...searchActionData,
            ...widgetData,
            ...data
        }
        Analytics.Scope().triggerClickStreamEvent("SEARCH", eventData)
        localStorage.setItem("searchAction", searchAction)
        localStorage.setItem("keyword", data.keyword || "")
    }catch(err){
        console.log("TRIGGER-SEARCH-CLICK-SERVICE: "+err)
    }
}