import React from 'react';
import TopSearchItem from './TopSearchItem';
import styles from '../search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const TopSearches = ({ data, ...props }) => {
    if (!data) return null;

    return (
        <div className={convertToModule(styles, "search-box__TS")}>
            <h6 className={convertToModule(styles, "search-box__title")}>TOP SEARCHES</h6>
            <div className={convertToModule(styles, "search-box__TS__items")}>
                {
                    data.map((item, index) => {
                        return <TopSearchItem index={index} ele={item} key={index} {...props}/>
                    })
                }
            </div>
        </div>
    );
}

export default TopSearches;