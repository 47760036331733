import React from 'react';
import SliderSkeleton from './desktopSliderSkeleton';
import VariantSkeleton from './variantSkeleton';
import styles from '../../../static//css/category/_desktop/index.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
class CatL2Desktop extends React.PureComponent {
    render() {
        return (
            <div className="hk-category-container full-width-skeleton">
                <div className="HK-pagecontainer">
                    <div className={convertToModule(styles,"category-main-container-skeleton cat-l2-desktop")}>
                        <div className="span4-skeleton filter-box" style={{ padding: "0", "border": "0" }}>
                            <div className="skeleton"></div>
                        </div>
                        <div className="span12-skeleton">
                            <div className="slider-skeleton">
                                <div className="banner skeleton"></div>
                            </div>
                            <SliderSkeleton/>
                            <VariantSkeleton />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CatL2Desktop;