import React from 'react';
import HK from 'factory/modules/HK'
import Link from 'components/Assets/LinkHref';
import IntersectionObserver from 'components/common/IntersectionObserver';
import styles from '../search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const RecentSearchItem = ({ ele, ...props }) => {

    const triggerInViewEvent = (data) => { 
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    widgetName: 'Search View',
                    eventName: "SEARCH_VIEW",
                    itemName: data && data.term,
                }
                HK.triggerComponentViewEvent(eventData);
            } catch (err) {
                console.log(err)
            }
        }
    }


    if (!ele) return;

    return (
        <Link to={"/search?txtQ=" + encodeURIComponent(ele.term).replace(/%20/g, '+')}
        onClick={() => {props.trackSearchClick(HK.config.searchActions.recentsearches || "", ele, props.itemPosition); props.onRecentSearchClick(ele);}} className={convertToModule(styles, "search-box__RS__item")}>
            {/* <img src={HK.getImage('/header/searchbox/clock.svg')} alt="clock.svg" /> */}
            <IntersectionObserver as ="p" onChange={props.trackInViewSearch(HK.config.searchActions.recentsearches || "", ele, props.itemPosition)}>
            {ele.term}
            </IntersectionObserver>
            <img src={HK.getImage('/header/searchbox/cross_v1.svg')} alt="cross.svg" className={convertToModule(styles, 'cancel-btn')} onClick={(event) => props.clearRecentSearchData(event, ele.term)} />
        </Link>
    );
}

export default RecentSearchItem;