import React, { Component } from 'react';
import HK from 'factory/modules/HK';
import { connect } from 'react-redux';
import CartLayer from 'components/Cart/CartLayerMenu';
import styles from '../../../../static/css/header.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';


const pagePattern = "1";

class CartMenu extends Component {
    componentWillUnmount() {
        HK.chatIcon(true);
    }
    componentDidMount() {
        HK.chatIcon(false);
    }
    render() {
        return (
            <React.Fragment>
                {/* {
                    this.props.menuIndex == 'cartmenu' && this.props.isCloseBtn && 
                    <div className={convertToModule(styles, (!HK.isMobile() ? "white " : "black ") + '')} onClick={this.props.clickHandler}>
                    </div>
                } */}
                <div className={convertToModule(styles, (this.props.menuIndex == 'cartmenu' ? "display-menu " : '') + 'vertical-menu-wrap cart-menu fromRight ' + this.props.className + (this.props._headerPromotionalTextReducer ? " promotext " : "") + (this.props.isCheckoutScreen ? " checkout-screen" : ""))}>
                    <div className={convertToModule(styles, "vertical-menu-inner")}>
                        <div className={convertToModule(styles, "vertical-menu-header")}>
                            {this.props.isCartPopup && <div className={convertToModule(styles, "menu-heading")}>
                                {this.props.isCloseBtn && <img onClick={this.props.clickHandler} className={convertToModule(styles, "close-btn cursor-pointer")} src={HK.getImage('/icons/close-icon.svg')} alt="warning" />}
                            </div>}
                        </div>
                        <div className={convertToModule(styles, "new-menu-heading")}>
                            <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                            </svg>
                            Item added to your cart
                        </div>
                        <div className={convertToModule(styles, "vertical-menu-content")}>
                            <div className={convertToModule(styles, "menu-actual-content")}>
                                <CartLayer menuActual={true} key={new Date()} clickHandler={this.props.clickHandler} pagePattern={pagePattern} {...this.props}></CartLayer>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        _headerPromotionalTextReducer: state.headerPromotionalTextReducer,
    }
}
export default connect(mapStateToProps, null)(CartMenu);
