import React, { Component } from 'react';
import HK from 'factory/modules/HK';

class ReferAndEarnTop extends Component {
    render() {
        return (
            <div className="refer-widget-topbar">
                <img alt={`refer-earn-icon.svg`} className="refer-widget-topbar-icon" src={HK.getImage('/common/misc/refer-earn-icon.svg')} />
                <p>Your friend <span data-refer-name="">{HK.getSearchParmas().ir_rname}</span> gave you Rs. 200 off on your first purchase. Use code <strong data-refer-code="">{HK.getSearchParmas().ir_uco}</strong></p>
                <div className="referWidget_closeclicked" onClick={this.props.closeReferAndEarnTop}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" enable-background="new 0 0 22 22" space="preserve"><rect x="-1" y="-1" fill="none" width="24" height="24"></rect><g><line fill="none" stroke="#424242" stroke-miterlimit="10" x1="0.4" y1="21.6" x2="21.6" y2="0.4"></line><line fill="none" stroke="#424242" stroke-miterlimit="10" x1="21.6" y1="21.6" x2="0.4" y2="0.4"></line></g></svg>
                </div>
            </div>

        );
    }
}

export default ReferAndEarnTop;