export const showAddressFormAction = (data) => {
    return {
        type:"SHOW_ADDRESS_FORM",
        payload:data
    }
}

export const addressFormDataAction = (data) => {
    return{
        type:"ADDRESS_FORM_DATA",
        payload:data
    }
}

export const addressListAction = (data) =>{
    return{
        type:"ADDRESS_LIST",
        payload:data
    }
}

export const ActiveAdddressAction = (data) =>{
    return{
        type:"ACTIVE_ADDRESS",
        payload:data
    }
}
