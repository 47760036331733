//React:::::::::::
import React, { useState } from 'react';

//Styles:::::::::::::::
import styles from "components/ReviewData/ReviewHeader/reviewHeader.module.scss";
import { convertToModule } from 'factory/utils/clientUtils';

//Components:::::::::::
import IntersectionObserver from 'components/common/IntersectionObserver';

//Services & methods :::::::::::
import HK from 'factory/modules/HK';
import { __localStorageGet } from 'factory/modules/HK_service';

//Analytics ::::::::::::::::
import Analytics from 'factory/modules/Analytics';
import UniversalAnalytics from 'factory/modules/UniversalAnalytics';
import PDPEventTracker from '../../../components/Pdp/common/PDPEventTracker';

//Redux and Store Actions
import { bindActionCreators } from 'redux';
import { withRouter } from 'next/router';
import { headerMenuAction } from 'components/common/header/action';
import { CARTAction } from 'components/common/header/action.js';
import { connect } from 'react-redux';


const AddToCart = ({ ...props }) => {
    const [showLoader, set_showLoader] = useState(false);
    const [addedState, set_addedState] = useState(false);
    const [btnFlag, set_btnFlag] = useState(false);
    const checkOut = (itrackUrl, /*event, eventName, eventCat, eventAction, eventLabel */) => {
        let customGoToCartData = {
            'eventname': props.varData.eventName,
            'widgetname': props.varData.widgetName,
            'Itemname': props.varData.name,
            'itemposition': props.varData.itemPosition,
            'WidgetPosition': props.varData.widgetPosition,
        };
        HK.triggerGoToCartEvent(customGoToCartData);
        window.location.href = "/cart/Cart.action" + itrackUrl;
    };

    const doCall = (event, eventName, eventCat, eventAction, eventLabel, multiFlag) => {
        event.stopPropagation();
        event.preventDefault();
        if (showLoader) return;
        if (btnFlag && !props.premium) {
            checkOut("?itracker=w:|refill-widget|;c:checkout|;", event, eventName, eventCat, eventAction, eventLabel);
            return;
        }
        if (addedState && !props.premium) {
             window.location.href = '/cart/Cart.action';
             return;
        }
        const isUserLoggedIn = __localStorageGet('isUserLoggedIn');
        const authtoken = HK.Cookies.get('authtoken');
        if (!(isUserLoggedIn !== null && isUserLoggedIn !== 'false' && authtoken && !btnFlag)) {
            let reqObj = {
                method: 'get',
                url: HK.config.APIS.userFlow.tempUserCreate
            };
            HK.loader(true);
            HK.ajaxCall(reqObj).then((res) => {
                if (!res.data.results.exception) {
                    HK.Cookies.set('authtoken', '"' + res.data.results.authToken + '"', 365);
                    localStorage.setItem('isUserLoggedIn', res.data.results.user.id);
                    localStorage.setItem('isTMPUser', 1);
                    HK.setCookieLoggedIn(res.data.results.user.id, 1);
                    if (multiFlag) {
                        multiPartCartCall();
                    } else {
                        _doAddToCartNowCall();
                    }

                }
                HK.loader(false);
            }).catch(err => {
                HK.errorPopup("Something went wrong, please try again.", 'Got: exception::' + reqObj.url);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                    errorMessage: err
                });
            });
            HK.loader(false);
        } else {
            if (multiFlag) {
                multiPartCartCall();
            } else {
                _doAddToCartNowCall();
            }
        }

        let data = {
            'event': eventName,
            'eventCategory': eventCat,
            'eventAction': eventAction,
            'eventLabel': eventLabel,
        };
        HK._setDataLayer(data);
    };
    const _doAddToCartNowCall = () => {
        let url = !props.isPack ? HK.config.APIS.cart.addVariant : HK.config.APIS.cart.addPack;
        let data = {};
        if (props.isPack) {
            data = {
                packId: props.id,
                storeVariantIdsInPackStr: props.storeVariantId
            };
        }
        else {
            data = {
                storeVariantId: props.id,
                vendorId: props.vendorID
            };
        }
        data.userId = HK.Cookies.get('assumeId') || localStorage.getItem('isUserLoggedIn');
        data.quantity = props.quantity || 1;
        if (props.isPdp && !HK.isMobile()) {
            set_showLoader(true);
        }

        HK.loader(true);
        HK.ajaxCall({
            url: url,
            method: 'post',
            data: data
        }).then(res => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                set_btnFlag(true);
                props._CARTAction(res.data.results.cartSummary);
                Analytics.dataLayerForCartUpdate(props.id, props.varData, props.isPack);
                if (props.wishlist) {
                    props.wishlistFlag(true);
                    props.removeWishlistItem(props.id);
                    window.location.href = '/cart/Cart.action';
                }
                if (!props.isPdp) {
                    if (props.wishlist) {
                        HK.toast('Product moved to Cart');
                    }
                    else {
                        HK.toast('Item Added to Cart');
                    }
                    !HK.isMobile() && props._headerMenuAction('cartmenu');
                } else {
                    set_showLoader(false);
                    if (props.isPdpMob && typeof props.showCartMenu === 'function') {
                        props.showCartMenu();
                    }
                    if (props.addToCartHandler) {
                        props.addToCartHandler();
                    } else {
                        //HK.isMobile() ? props.history.push('/cart/Cart.action') : props._headerMenuAction('cartmenu');
                        !HK.isMobile() && props._headerMenuAction('cartmenu');
                    }
                }
                set_addedState(true);
                if (!props.wishlist) {
                    HK.toast('Item Added to Cart');
                }

                if (typeof props.handleAddToCartClickCallback === "function") {
                    props.handleAddToCartClickCallback({
                        widgetName: props.widgetName,
                        eventName: props.eventName,
                        eventCategory: props.eventCategory,
                        widgitPosition: props.widgetIndex
                    });
                }
                if (typeof props.addToCartCB === 'function')
                    props.addToCartCB();
                if (props.isPack && !props.isCombo) {
                    const varData = { ...props.varData };
                    // varData.category = "";
                    // varData.brName = "";
                    Analytics.Scope().buyNowAddTocart(varData, 'AddTocart', props.quantity || 1);
                } else if (props.isCombo && props.getFullProductArr) {
                    let addToCartEventDatalayer;
                    const packSv = props.getFullProductArr();
                    addToCartEventDatalayer = {
                        ...props.varData,
                        packSv: packSv,
                        fb_productId: props.id,
                        isPack: props.isPack
                    };
                    Analytics.Scope().buyNowAddTocart(addToCartEventDatalayer, 'AddCombo', props.quantity || 1);
                } else if (props.event === 'YOU_MAY_LIKE') {
                    let addToCartEventDatalayer;
                    addToCartEventDatalayer = {
                        ...props.varData,
                        fb_productId: props.id,
                        isPack: props.isPack,
                        ind: props.variantPosition,
                        event_name: 'YOU_MAY_LIKE'
                    };
                    Analytics.Scope().buyNowAddTocart(addToCartEventDatalayer, 'AddTocart', props.quantity || 1);
                }
                else {
                    let eventData;
                    eventData = {
                        ...props.varData,
                        ind: props.variantPosition,
                    };

                    Analytics.Scope().buyNowAddTocart(eventData, 'AddTocart', props.quantity || 1);
                }

                const clickStream = {
                    widgetName: props.varData && props.varData.title || "",
                    eventName: props.varData && props.varData.eventName || "",
                    brand: props.varData && props.varData.brName || "",
                    category: props.varData && props.varData.category || "",
                    itemName: props.varData && props.varData.name || "",
                    id: props.varData && props.varData.id || "",
                    offerPrice: props.varData && props.varData.offerPrice || 0,
                    quantity: props.quantity || 1,
                    widgetPosition: props.varData && props.varData.widgetPosition || "",
                    itemPosition: props.varData && props.varData.itemPosition || ""
                };

                /* rudderstack */
                UniversalAnalytics.ADD_TO_CART(props.varData, props.quantity);

                if (props.customAddToCart && props.customAddToCart === true) {
                    if (typeof props.customAddToCartEvent === "function") {
                        let tempClickStream, clickStreamData;
                        if (props.customVarData) {
                            tempClickStream = {
                                id: props.customVarData.id ? props.customVarData.id : "",
                                itemName: props.customVarData.name ? props.customVarData.name : "",
                                brand: props.customVarData.brName ? props.customVarData.brName : "",
                                offerPrice: props.customVarData.offerPrice ? props.customVarData.offerPrice : 0,
                                itemPosition: props.customVarData.itemPosition || 1
                            };
                        }
                        else {
                            tempClickStream = {
                                id: clickStream.id,
                                itemName: clickStream.itemName,
                                brand: clickStream.brand,
                                offerPrice: clickStream.offerPrice,
                            };
                        }

                        if (props.customWidgetName && props.customWidgetName !== "") {
                            clickStreamData = {
                                ...tempClickStream,
                                widgetName: props.customWidgetName ? props.customWidgetName : ""
                            };
                        }
                        else {
                            clickStreamData = {
                                ...tempClickStream
                            };
                        }

                        props.customAddToCartEvent({
                            ...clickStreamData,
                            qty: 1
                        });
                    }
                }
                else {
                    HK.triggerComponentClickEvent(PDPEventTracker.getData(clickStream, props.reviewData));
                }


            } else {
                set_showLoader(false);
                HK.errorPopup(res.data.results.msgs[0]);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
            HK.loader(false);
        }).catch(err => {
            console.log(err);
            set_showLoader(false);
            HK.loader(false);
            HK.errorPopup("Something went wrong, please try again.");
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
        });
    };


    //multipart form data api call
    const multiPartCartCall = () => {
        let url = HK.config.APIS.cart.multiVariants;
        let cartVariantList = props.cartProduct;
        let data = {};
        if (props.isPack) {
            data = {
                cartVariantList
            };
        }
        else {
            data = {
                cartVariantList
            };
        }
        data.userId = HK.Cookies.get('assumeId') || localStorage.getItem('isUserLoggedIn');
        data.quantity = props.quantity || 1;
        if (props.isPdp && !HK.isMobile()) {
            set_showLoader(true);
        }
        HK.loader(true);
        HK.ajaxCall({
            url: url,
            method: 'post',
            data: data
        }).then(res => {
            if (!res.data.results.exception) {
                props._CARTAction(res.data.results.cartSummary);
                Analytics.dataLayerForCartUpdate(props.id, props.varData, props.isPack);
                if (!props.isPdp) {
                    HK.toast('Item Added to Cart');
                    // HK.isMobile() ? props.history.push('/cart/Cart.action') : props._headerMenuAction('cartmenu');
                    !HK.isMobile() && props._headerMenuAction('cartmenu');
                } else {
                    set_showLoader(false);
                    if (props.addToCartHandler) {
                        props.addToCartHandler();
                    } else {
                        //HK.isMobile() ? props.history.push('/cart/Cart.action') : props._headerMenuAction('cartmenu');
                        !HK.isMobile() && props._headerMenuAction('cartmenu');
                    }
                }
                HK.toast('Item Added to Cart');
                if (typeof props.addToCartCB === 'function')
                    props.addToCartCB();
                Analytics.Scope().buyNowAddTocart(props.varData, 'AddTocart', props.quantity || 1);
                const clickStream = {
                    widgetName: props.varData && props.varData.title || "",
                    eventName: props.varData && props.varData.eventName || "",
                    brand: props.varData && props.varData.brName || "",
                    category: props.varData && props.varData.category || "",
                    itemName: props.varData && props.varData.name || "",
                    id: props.varData && props.varData.id || "",
                    offerPrice: props.varData && props.varData.offerPrice || 0,
                    quantity: props.quantity || 1,
                    widgetPosition: props.varData && props.varData.widgetPosition || "",
                    itemPosition: props.varData && props.varData.itemPosition || ""
                };
                HK.triggerComponentClickEvent(PDPEventTracker.getData(clickStream, props.reviewData));

            } else {
                set_showLoader(false);
                HK.errorPopup('Could not process your request. Please try again later.', 'Got: exception::' + url);
                HK.reportErrorToDashboard({
                    stackTrace: new Error().stack,
                });
            }
            HK.loader(false);
        }).catch(err => {
            console.log(err);
            set_showLoader(false);
            HK.errorPopup('Could not process your request. Please try again later.', 'Got: ' + err + '::' + url);
            HK.reportErrorToDashboard({
                stackTrace: new Error().stack,
                errorMessage: err
            });
            HK.loader(false);
        });
    };

    // const gotCartCall=() =>{
    //     Analytics.Scope().buyNowAddTocart(props.varData, 'GotoCart', props.quantity || 1);
    //     HK.isMobile() ? props.history.push('/cart/Cart.action') : props._headerMenuAction('cartmenu');
    // };
    const triggerInViewEvent = (/*data*/) => {
        if (props.flag != "recomendation") {
            return (inView) => {
                if (!inView) return;
                try {
                    const eventData = {
                        widgetName: props.varData && props.varData.title || "",
                        eventName: props.varData && props.varData.eventName || "",
                        brand: props.varData && props.varData.brName || "",
                        category: props.varData && props.varData.category || "",
                        itemName: props.varData && props.varData.name || "",
                        id: props.varData && props.varData.id || "",
                        offerPrice: props.varData && props.varData.offerPrice || 0,
                        widgetPosition: props.varData && props.varData.widgetPosition || "",
                        itemPosition: props.varData && props.varData.itemPosition || ""
                    };
                    HK.triggerComponentViewEvent(PDPEventTracker.getData(eventData, props.reviewData));
                } catch (err) {
                    console.log(err);
                }
            };
        }
    };
    return (
        <IntersectionObserver className={convertToModule(styles, `hk-elements--addToCart ${(addedState ? 'hk-elements--addToCart-added' : '')} ${props.wrapperClass ? props.wrapperClass : ''}`)} onChange={triggerInViewEvent(props)}>

            {

                !props.buttonDisplayName && !props.multiflag &&
                <button type="button" className={convertToModule(styles, btnFlag ? 'track-add-to-cart addToCart tr-addPackToCart AddToCartnew added ' + props.className : 'track-add-to-cart addToCart tr-addPackToCart AddToCartnew ' + props.className)} onClick={(event) => doCall(event, props.event, props.eventCat, props.eventAct, props.varData.name)}>
                    {props.isCombo ? (HK.vernacular.variantNew_m_buy_combo[props.ln]) : props.isPdp && addedState ? "Go to Cart >" : HK.vernacular.variantNew_input_addtocart[props.ln] || (props.isHeader ? "Add" : addedState ? 'Go to Cart >' : ("Add to Cart"))}</button>}
            {
                ((!addedState || props.premium) && props.buttonDisplayName && !props.multiflag)&&
                <button type="button" className={convertToModule(styles, `track-add-to-cart addToCart tr-addPackToCart AddToCartnew ${props.className}`)} onClick={(event) => doCall(event, props.event, props.eventCat, props.eventAct, props.varData.name)}>
                    {!props.isImage ? null : <img src={HK.getImage('/icons/shopping-cart.svg')} alt="shoping-cart" />}{props.buttonDisplayName && <span>{props.buttonDisplayName == "Move to cart" ? <img className='wish-img' src={HK.getImage('/common/shopcart.svg')} alt="wishlist" /> : ''}</span>}{props.buttonDisplayName}
                </button>
            }
            {
                !props.buttonDisplayName && props.multiflag &&
                <button type="button" className={convertToModule(styles, 'track-add-to-cart addToCart tr-addPackToCart AddToCartnew')} onClick={(event) => doCall(event, props.event, props.eventCat, props.eventAct, props.varData.name, props.multiflag)}>
                    {props.isCombo ? (HK.vernacular.variantNew_m_buy_combo[props.ln]) : props.isPdp ? HK.vernacular.variantNew_input_addtocart[props.ln] : (props.isHeader ? "+ Addsj" : "Add to Cart")}</button>
            }
            {
                showLoader && <div className={convertToModule(styles, "hk-loader-default")}><div></div></div>
            }
        </IntersectionObserver>
    );
};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        _CARTAction: CARTAction,
        _headerMenuAction: headerMenuAction
    }, dispatch);
}
function mapStateToProps(state) {
    return {
        _MenuReducer: state.MenuReducer
    };
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddToCart)
);