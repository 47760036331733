import React from 'react';
import HK from 'factory/modules/HK'
import { connect } from 'react-redux';
import Link from 'components/Assets/LinkHref';
import styles from '../desktop-header.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
import { useEffect } from 'react';
import { useState } from 'react';
const CartIcon = ({ CART, cartHandler, cartClick, isMobile }) => {
    const [iconSize, setIconSize] = useState();
    const onCartClick = () => {
        let eventData = {
            'event': 'ls_MB_Top-Headers',
            'eventCategory': 'Header',
            'eventAction': "Proceed to Cart",
            'eventLabel': "",
        }
        HK._setDataLayer(eventData);
        const clickstream = {
            widgetName: 'Cart',
            eventName: "Top_Navigation",

        }
        // window.location.href="/cart/Cart.action"
        HK.triggerComponentClickEvent(clickstream);
    }

    const getIconSize = () => {
        if(isMobile){
            setIconSize(24)
        }else{
            setIconSize(32)
        }
    }

    useEffect(()=>{
        getIconSize();
    },[isMobile])

    return (
        <Link nextJSReload={true} to='/cart/Cart.action' className={convertToModule(styles, "header-top__icons__cart")} onClick={onCartClick}>
                <svg width="29" height="27" viewBox="0 0 29 27" fill="none"><path d="M11 26C11.5523 26 12 25.3284 12 24.5C12 23.6716 11.5523 23 11 23C10.4477 23 10 23.6716 10 24.5C10 25.3284 10.4477 26 11 26Z" stroke="#1C1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24 26C24.5523 26 25 25.3284 25 24.5C25 23.6716 24.5523 23 24 23C23.4477 23 23 23.6716 23 24.5C23 25.3284 23.4477 26 24 26Z" stroke="#1C1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1 1H5.90909L9.19818 17.9603C9.31041 18.5434 9.6178 19.0672 10.0665 19.4401C10.5153 19.8129 11.0768 20.0109 11.6527 19.9995H23.5818C24.1578 20.0109 24.7193 19.8129 25.168 19.4401C25.6168 19.0672 25.9241 18.5434 26.0364 17.9603L28 7.33318H7.13636" stroke="#1C1C28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <span>{CART.noItemsInCart || 0}</span>
        </Link>
    );
}

function mapStateToProps(state) {
    return {
        CART: state.CARTReducer,
    };
}
export default connect(mapStateToProps)(CartIcon);