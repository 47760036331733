import React from 'react';
import IntersectionObserver from 'components/common/IntersectionObserver';
import HK from 'factory/modules/HK'
import styles from '../search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const SuggestedProductsItem = ({item, onSuggestedProductClick, itemPosition, trackInViewSearch, trackSearchClick, searchInput}) => {

    const triggerInViewEvent = (data) => { 
        return (inView) => {
            if (!inView) return;
            try {
                const eventData = {
                    widgetName: 'Search View',
                    eventName: "SEARCH_VIEW",
                    itemName: data && data.nm,
                    landingUrl: data && data.url,
                    id:data && data.id,
                    price: data && data.mrp,
                    offerPrice: data && data.offer_pr

                }
                HK.triggerComponentViewEvent(eventData);
            } catch (err) {
                console.log(err)
            }
        }
    }

    const returnUrl = (url) => {
        try{
            let link = url
            url && searchInput ? link += "&search="+searchInput.replace(/ /g,'+') : link += "&search"
            return link
        }catch(err){
            console.log("return-url-suggestedpdct: "+err)
        }
    }

    return (
        <IntersectionObserver as="a" href={returnUrl(item.url)} className={convertToModule(styles, "search-box__SP__item")} onChange={trackInViewSearch(HK.config.searchActions.suggestedproducts || "", item, itemPosition)} onClick={() => {trackSearchClick(HK.config.searchActions.suggestedproducts || "", item, itemPosition)}}>
            <img src={item.s_link} alt=''onClick={() => {onSuggestedProductClick(item); }}/>
            <h6 onClick={() => {onSuggestedProductClick(item);}}>{item.nm}</h6>
        </IntersectionObserver>
        
    );
}

export default SuggestedProductsItem;