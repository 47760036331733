import React from 'react';
import SuggestedProductsItem from 'components/common/header/contains/SearchBox/SuggestedProducts/SuggestedProductsItem.jsx'
import styles from '../search-box.module.scss'
import { convertToModule } from 'factory/utils/clientUtils';
const SuggestedProducts = ({acPacks, acVariants, searchInput, onSuggestedProductClick, trackInViewSearch, trackSearchClick}) => {
    if(!acPacks || acPacks.length===0) return null;

    let acVariantLimit = acVariants && acVariants.length ? acVariants.length : 0

    let lastPositionValue = 0

    return (
        <div className={convertToModule(styles, "search-box__SP")}>
            <h6 className={convertToModule(styles, "search-box__title 1")}>Suggested products : {searchInput}</h6>
            <div className={convertToModule(styles, "search-box__SP__items")}>
                {
                    acPacks.map((item, index) => {
                        return <SuggestedProductsItem item={item} key={index} onSuggestedProductClick={onSuggestedProductClick} itemPosition={lastPositionValue} trackInViewSearch={trackInViewSearch} trackSearchClick={trackSearchClick} searchInput={searchInput}/>
                    })
                }
                {
                    acVariants && acVariants.slice(0, acVariantLimit).map((item, index) => {
                        return <SuggestedProductsItem item={item} key={index} onSuggestedProductClick={onSuggestedProductClick} itemPosition={lastPositionValue > 0 ? lastPositionValue+(++index) : ++index} trackInViewSearch={trackInViewSearch} trackSearchClick={trackSearchClick} searchInput={searchInput}/>
                    })
                }
            </div>
        </div>
    );
}
 
export default SuggestedProducts;